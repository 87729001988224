









































































































































































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { QuestionTypeKeyFunction } from '../../types/questions.type';
import {
  ExamBasicData,
  QuestionTypes,
  Spec,
  FetchExamDataRes,
  SpecQuestion,
  ExamIlos,
  ExamSubjects,
  ExamMatrixRecord,
  SpecAddFilters,
  ModelDist,
  SimilarityData,
  QuestionTypeCount,
  DifficultyExamType,
  ExamInfo,
  Model,
  LabelsConfig,
} from '../../types/exam.type';

import AddQuestionDialogComponent from './addQuestionDialog.component.vue';
import SpecTypeDialogComponent from './specTypeDialog.Component.vue';
import ExamProvider from '../../providers/exam.provider';
import DialogComponent from '../dialog.component.vue';
import {
  APPIDS,
  QUESION_TYPES,
  MODELS_MATCH,
  SUPPORTED_LANGS,
} from '../../enums';
import { Lang } from '../../types/general.type';
import MatrixBlueprintComponent from '../MatrixBlueprint.component.vue';
import DegreesDistributionForm from './degreesDistributionForm.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import ExamchartComponent from './examChart.component.vue';
import ExamModelPaper from './ExamModelPaper.component.vue';
import ViewMatchingColumns from '../questions/matching/viewMatchingColumns.vue';
import ViewCaseStudySubQuestions from './ViewCaseStudySubQuestions.component.vue';
import SessionRefreshComponent from '../../shared/sessionRefresh.component.vue';

@Component({
  components: {
    DialogComponent,
    MatrixBlueprintComponent,
    DegreesDistributionForm,
    ExamchartComponent,
    AddQuestionDialogComponent,
    SpecTypeDialogComponent,
    ExamModelPaper,
    ViewMatchingColumns,
    ViewCaseStudySubQuestions,
    SessionRefreshComponent,
  },
})
export default class ModelsAddEditComponent extends mixins(
  Toaster,
  MathJaxMixin,
) {
  examId = this.$route.params.examId;

  courseId = this.$route.params.courseId;

  appId = APPIDS.EXAM_GENERATION;

  shuffle = this.$route.query.shuffle;

  disableAutoRenederEquations = true;

  shuffleKey = '';

  modelNo = this.$route.params.modelNo;

  autoGenerated = this.$route.query.auto;

  loading = false;

  labelsConfig: LabelsConfig | undefined = {};

  fractionNumber = 2;

  examData: ExamBasicData = {
    exam: {
      _id: '',
      course: {},
      examInfo: {
        totalDegree: 0,
        examTypeName: '',
        semesterName: '',
        minDifficulty: 0,
        maxDifficulty: 0,
        questionTypesCounts: [],
        instructorId: '',
        modelsMatch: '',
        examSignature: '',
        examDate: '',
        Degree: false,
      },
      matrix: [],
      isDeleted: false,
    },
  };

  error: Lang | null = null;

  specs: Spec[] | undefined = [];

  questionFilters = {};

  selectedSpec: Spec = {
    questions: [],
  };

  examSubjects: ExamSubjects[] | undefined = [];

  examIlos: ExamIlos[] | undefined = [];

  examDifficulty: DifficultyExamType[] | undefined = [];

  questionTypesKeys = QUESION_TYPES;

  actionType = 'add';

  valid = true;

  openPanels: number[] = [];

  showbackConfirm = false;

  showNoSimilarDialog = false;

  showExamPaperDialog = false;

  showModelBlueprint = false;

  showSimilarityDialog = false;

  specAddFilters: SpecAddFilters = {
    pageNo: 0,
  };

  isEditMode = false;

  isCopyMode = false;

  saveLoading = false;

  applyExactMatch = false;

  loadingModelSimilarity = false;

  ilosData: ExamIlos[] = [];

  subjectsData: ExamSubjects[] = [];

  matrixData: ExamMatrixRecord[] = [];

  similarityData: SimilarityData = {};

  formalizedModel = {};

  model: Model = {};

  examInfo: ExamInfo = {
    totalDegree: 0,
    examTypeName: '',
    semesterName: '',
    minDifficulty: 0,
    maxDifficulty: 0,
    questionTypesCounts: [],
    instructorId: '',
    modelsMatch: '',
    examSignature: '',
    examDate: '',
    Degree: false,
  };

  difficultyLevelRanges: DifficultyExamType[] = [];

  rules = {
    specHeader: [
      (val: string) => !!val || this.$t('validations.required'),
      (val: string) => val.length <= 50 || this.$t('validations.maxLength', { max: 50 }),
    ],
    questionDegree: [
      (val: string) => !!val || this.$t('validations.required'),
      (val: number) => val >= 0.01 || this.$t('validations.minValue', { min: 0.01 }),
      (val: number) => val <= 999 || this.$t('validations.maxValue', { max: 999 }),

      (val: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(this.parseNumber(val).toString())
        || this.$t('validations.maxFloating'),
    ],
  };

  selectedQuestionsIds: number[] = [];

  $refs!: {
    form: HTMLFormElement;
    SpecTypeDialog: SpecTypeDialogComponent;
    AddQuestionDialog: AddQuestionDialogComponent;
  };

  questionTypes: QuestionTypes[] | undefined = [];

  specType = '';

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }

  get examLangId() {
    return this.examData?.exam?.examInfo?.examLang || '';
  }

  get examCourseIlosObj() {
    return (
      this.examData?.exam?.course?.ilos?.reduce((headerIlosAcc, iloData) => {
        if (!iloData.headerId || !iloData.ilodId) {
          return headerIlosAcc;
        }
        /* eslint-disable no-param-reassign */
        if (!headerIlosAcc[iloData.headerId]) {
          headerIlosAcc[iloData.headerId] = [];
        }
        /* eslint-enable no-param-reassign */
        headerIlosAcc[iloData.headerId].push(iloData.ilodId);
        return headerIlosAcc;
      }, {}) || {}
    );
  }

  get invalidModelMatch() {
    return (
      this.applyExactMatch
      && this.specs?.some((spec) => !this.validateSpecPerfectMatch(spec))
    );
  }

  get invalidTotalDegrees() {
    return !(
      this.examData?.exam?.examInfo?.totalDegree
      && Number(this.examData?.exam?.examInfo?.totalDegree.toFixed(this.fractionNumber))
        === Number(this.modelDistData.totalDegrees.toFixed(this.fractionNumber))
    );
  }

  get examDirection() {
    return this.examData?.exam?.examInfo?.examLang === SUPPORTED_LANGS.AR
      ? 'rtl'
      : 'ltr';
  }

  get filteredModelSpecs() {
    if (!this.specs) {
      return [];
    }
    return this.specs.reduce((specsAcc, spec) => {
      if (
        spec.questions?.some((ques) => this.selectedQuestionsIds.includes(
          ques.questionId || (ques.id as number),
        )
        )
      ) {
        const filteredSpec = { ...spec };

        /* eslint-disable max-len */

        filteredSpec.questions = filteredSpec.questions.filter((ques) => this.selectedQuestionsIds.includes(
          ques.questionId || (ques.id as number),
        ));
        specsAcc.push(filteredSpec);
      }
      return specsAcc;
    }, [] as Spec[]);
  }

  get usedQuestions() {
    if (!this.specs) {
      return [];
    }
    return this.specs.reduce((acc, spec) => {
      acc.push(
        ...(spec.questions.map(
          (question) => question.questionId || question.id,
        ) as number[]),
      );
      return acc;
    }, [] as number[]);
  }

  get modelDistData(): ModelDist {
    return this.filteredModelSpecs.reduce(
      (modelAcc, spec) => {
        /* eslint-disable no-param-reassign */
        if (!spec.questions || !spec.questions.length) {
          return modelAcc;
        }

        const { specTotalTime, specTotalDifficulty } = this.getSpecDistDataObj(
          spec.questions,
        );
        modelAcc.totalQuestionsNo += spec.questions.length;
        modelAcc.totalTime += specTotalTime;
        modelAcc.totalDifficulty += specTotalDifficulty;
        modelAcc.totalDegrees = Number(
          (
            modelAcc.totalDegrees
            + spec.questions.reduce((acc, item) => acc + Number(item.degree), 0)
          ).toFixed(this.fractionNumber),
        );
        /* eslint-enable no-param-reassign */
        return modelAcc;
      },
      {
        totalTime: 0,
        totalDifficulty: 0,
        totalQuestionsNo: 0,
        totalDegrees: 0,
      },
    );
  }

  async mounted() {
    if (this.$route.name === 'copy-model') {
      this.isCopyMode = true;
      this.actionType = 'copy';
    } else if (this.$route.name === 'edit-model') {
      this.isEditMode = true;
      this.actionType = 'edit';
    } else {
      this.actionType = 'add';
    }
    await this.getModel();
    setTimeout(() => {
      this.renderEquations();
    }, 0);
  }

  parseNumber(stringVal) {
    const number = Number(stringVal);
    return Number.isNaN(number) ? 0 : number;
  }

  openQueestionPanels(questions: SpecQuestion[]) {
    return questions.map((itm, index) => index);
  }

  updateChecked(id: number) {
    const questionId = this.selectedQuestionsIds.find((item) => item === id);
    if (questionId) {
      this.selectedQuestionsIds.splice(
        this.selectedQuestionsIds.indexOf(id),
        1,
      );
    } else {
      this.selectedQuestionsIds.push(id);
    }
  }

  checkedQuestion(id: number) {
    return !!this.selectedQuestionsIds.find((item) => item === id);
  }

  validateSpecPerfectMatch(spec) {
    const specCheckedQuestions = this.getCheckedQuestions(spec.questions);
    const questionsWithValidAnswers = specCheckedQuestions.filter(
      (ques) => ques.answers && ques.answers.length === spec.answersCount,
    );
    return (
      specCheckedQuestions.length === spec.questionCount
      && ([
        this.questionTypesKeys.T_F.id,
        this.questionTypesKeys.ESSAY.id,
        this.questionTypesKeys.CASE_STUDY.id,
        this.questionTypesKeys.MATCHING.id,
      ].includes(spec.type)
        || (spec.answersCount
          && questionsWithValidAnswers.length === spec.questionCount))
    );
  }

  getCheckedQuestions(specQuestions) {
    return specQuestions.filter((ques) => this.selectedQuestionsIds.includes(ques.questionId || ques.id));
  }

  async getModel() {
    this.loading = true;
    try {
      const res: FetchExamDataRes = await ExamProvider.fetchExamData(
        this.appId,
        this.courseId,
        this.examId,
        {
          modelNo: this.modelNo,
          shuffle: this.shuffle,
          auto: this.autoGenerated,
        },
      );
      this.examData = res.examData;
      this.labelsConfig = res.labelsConfig;
      this.examSubjects = this.examData.subjects;
      this.examIlos = this.examData.ilos;
      this.examDifficulty = this.examData.difficultyLevelRanges;
      this.questionTypes = this.examData.questionTypes;
      this.examInfo = this.examData.exam.examInfo;
      this.shuffleKey = this.examData.shuffleKey || '';
      if (!this.autoGenerated && this.examData?.exam?.models) {
        this.specs = this.examData.exam.models[0]?.specs || [];
      } else {
        this.specs = res.autoGenerated.arrangedExam || [];
      }
      if (
        this.examData?.exam?.examInfo?.modelsMatch
        && this.examData.exam.examInfo.modelsMatch === MODELS_MATCH.PERFECT_MATCH
      ) {
        this.applyExactMatch = true;
        this.setExactMatchSpecs(
          this.examData.exam.examInfo.questionTypesCounts,
        );
      } else {
        this.setSpecsHeader();
      }

      if (this.specs) {
        this.openPanels = this.specs?.map((item, index) => {
          this.selectedQuestionsIds.push(
            ...item.questions.map((question) => question.questionId as number),
          );
          return index;
        });
      }
      if (this.isCopyMode || this.isEditMode) {
        this.specs = this.specs.map((spec: Spec) => ({
          ...spec,
          questions: spec.questions.map((question: SpecQuestion) => ({
            ...question,
            notChanged: true,
          })),
        }));
      }

      this.ilosData = (this.examData?.ilos || []) as unknown as ExamIlos[];
      this.subjectsData = (this.examData?.subjects
        || []) as unknown as ExamSubjects[];
      this.matrixData = (this.examData?.exam?.matrix
        || []) as unknown as ExamMatrixRecord[];

      this.loading = false;
    } catch (err) {
      this.error = err;
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  setExactMatchSpecs(questionTypesCounts: QuestionTypeCount[] = []) {
    questionTypesCounts.forEach((quesType, index) => {
      let typeSpecIndex = -1;
      if (this.specs && this.specs[index]) {
        if (quesType.answersCount) {
          typeSpecIndex = this.specs[index].type === quesType.questionType
            && Number(this.specs[index].numberOfAnswers) === quesType.answersCount
            ? index
            : -1;
        } else {
          typeSpecIndex = this.specs[index].type === quesType.questionType ? index : -1;
        }
      }
      const typeSpec = {
        header: this.getSpecHeader(quesType.questionType),
        numberOfAnswers: quesType.answersCount,
        type: quesType.questionType,
        questionCount: quesType.questionCount,
        answersCount: quesType.answersCount,
        validPanel: true,
        questions: ((typeSpecIndex !== -1
          && this.specs
          && (this.autoGenerated === 'true' || this.actionType !== 'add')
          && this.specs[typeSpecIndex]?.questions)
          || []) as SpecQuestion[],
      } as unknown as Spec;

      if (this.specs) {
        if (
          typeSpecIndex === -1
          || (this.autoGenerated !== 'true' && this.actionType === 'add')
        ) {
          this.specs.push(typeSpec);
        } else {
          this.specs.splice(typeSpecIndex, 1, typeSpec);
        }
      }
    });
  }

  getSpecHeader(questionType: string) {
    return (this.examLangId === SUPPORTED_LANGS.AR
      ? (
        QUESION_TYPES[questionType] as QuestionTypeKeyFunction
      )()?.defaultText?.ar
      : (
        QUESION_TYPES[questionType] as QuestionTypeKeyFunction
      )()?.defaultText?.en) || '';
  }

  setSpecsHeader() {
    if (this.specs && this.specs.length) {
      this.specs = this.specs.map((spec) => ({
        ...spec,
        header: this.getSpecHeader(spec.type || ''),
      }));
    }
  }

  addQuestion(spec, index) {
    this.$refs.AddQuestionDialog.open(
      false,
      spec.type,
      spec.numberOfAnswers,
      '',
      '',
      index,
    );
  }

  async replaceQuestion(specIndex, questionIndex, question) {
    try {
      if (this.specs) {
        const questionId = this.specs[specIndex]?.questions[questionIndex]
          ?.questionId as number;
        const usedQuestions = this.getQuestionTypeUsedQuestionsIds(
          question.typeId,
        );
        const similarQuestion: SpecQuestion = await ExamProvider.getSimilarQuestion(
          this.appId,
          this.courseId,
            questionId as number,
            {
              usedQuestions,
              subjId: question.subjId,
              ilosHeaderId: question.iloId,
              ilosIds: this.examCourseIlosObj[question.iloId] || [],
              questionType: question.typeId,
              examId: this.examId,
            },
        );
        if (!similarQuestion) {
          this.showNoSimilarDialog = true;
          return;
        }

        if (this.specs[specIndex]) {
          this.specs[specIndex].questions.splice(
            questionIndex,
            1,
            similarQuestion,
          );
        }
        const selectedQuestionIndex = this.selectedQuestionsIds.findIndex(
          (selectedQuesId) => selectedQuesId === questionId,
        );
        if (selectedQuestionIndex !== -1) {
          this.selectedQuestionsIds.splice(
            selectedQuestionIndex,
            1,
            similarQuestion.questionId as number,
          );
        }
      }
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  deleteQuestion(specIndex, questionIndex) {
    if (this.specs) {
      this.specs[specIndex].questions.splice(questionIndex, 1);
    }
  }

  getQuestionTypeUsedQuestionsIds(questionType) {
    if (!questionType || !this.specs) {
      return [];
    }
    return this.specs
      .filter((spec) => spec.type === questionType)
      .reduce((acc, spec) => {
        acc.push(
          ...(spec.questions.map((question) => question.questionId) as number[]),
        );
        return acc;
      }, [] as number[]);
  }

  distributeDegreesEqually() {
    /* eslint-disable no-param-reassign */
    this.filteredModelSpecs.forEach((spec) => {
      spec.questions.forEach((_, quesIndex) => {
        spec.questions[quesIndex].degree = Number(
          (
            (this.examData?.exam?.examInfo?.totalDegree
              || this.modelDistData.totalQuestionsNo)
            / this.modelDistData.totalQuestionsNo
          ).toFixed(this.fractionNumber),
        );

        if (spec.type === this.questionTypesKeys.MATCHING.id) {
          this.reCalculateSubQuestionsDegrees(
            spec.questions[quesIndex].matchingSubQuestions,
            spec.questions[quesIndex].degree,
          );
          spec.questions[quesIndex].degree = this.reCalculateTotalDegreeFromSubQuestions(
            spec.questions[quesIndex].matchingSubQuestions,
          );
        } else if (spec.type === this.questionTypesKeys.CASE_STUDY.id) {
          this.reCalculateSubQuestionsDegrees(
            spec.questions[quesIndex].caseStudySubQuestions,
            spec.questions[quesIndex].degree,
          );
          spec.questions[quesIndex].degree = this.reCalculateTotalDegreeFromSubQuestions(
            spec.questions[quesIndex].caseStudySubQuestions,
          );
        }
      });
    });
    /* eslint-enable no-param-reassign */
  }

  reCalculateSubQuestionsDegrees(subQuestions, totalDegree) {
    /* eslint-disable no-param-reassign */
    subQuestions.forEach((subQuestion) => {
      subQuestion.degree = Number(
        Number(totalDegree / subQuestions.length)
          .toFixed(this.fractionNumber),
      );
    });
  }

  reCalculateSpecTotalDegree(data) {
    if (this.specs && this.specs.length) {
      this.specs[data.SpecIndex].questions[data.questionIndex].degree = Number(
        Number(data.totalDegree).toFixed(this.fractionNumber),
      );
    }
  }

  reCalculateTotalDegreeFromSubQuestions(subQuestions) {
    return subQuestions.reduce(
      (sum, current) => Number((Number(sum.toFixed(this.fractionNumber)) + Number(Number(current.degree).toFixed(this.fractionNumber))).toFixed(this.fractionNumber)),
      0,
    );
  }

  calcTimeInMin(time) {
    const timeArray = time.split(':');
    return (
      Number(timeArray[0]) * 60
      + Number(timeArray[1])
      + Number((Number(timeArray[2]) / 60).toFixed(0))
    );
  }

  getSpecDistDataObj(questions) {
    return questions.reduce(
      (specAcc, ques) => {
        /* eslint-disable no-param-reassign */
        specAcc.specTotalTime += this.calcTimeInMin(ques.time);
        specAcc.specTotalDifficulty += Number(ques.difficulty);
        /* eslint-enable no-param-reassign */
        return specAcc;
      },
      { specTotalTime: 0, specTotalDifficulty: 0 },
    );
  }

  validateTotals(dist) {
    if (dist.time && dist.difficulty) {
      return !!(
        this.modelDistData.totalTime + this.modelDistData.totalDifficulty
      );
    }
    return (
      (dist.time && this.modelDistData.totalTime)
      || (dist.difficulty && this.modelDistData.totalDifficulty)
    );
  }

  distributeDegrees(distribution) {
    const validTotals = this.validateTotals(distribution);
    /* eslint-disable no-param-reassign */
    this.filteredModelSpecs.forEach((spec) => {
      spec.questions.forEach((_, quesIndex) => {
        if (!validTotals) {
          spec.questions[quesIndex].degree = Number(
            (
              (this.examData?.exam?.examInfo?.totalDegree
                || this.modelDistData.totalQuestionsNo)
              / this.modelDistData.totalQuestionsNo
            ).toFixed(this.fractionNumber),
          );
        } else {
          spec.questions[quesIndex].degree = Number(
            (
              ((distribution.difficulty
              && this.modelDistData.totalDifficulty
              && spec.questions[quesIndex]?.difficulty
                ? spec.questions[quesIndex].difficulty
                  / this.modelDistData.totalDifficulty
                : 0)
                + (distribution.time && this.modelDistData.totalTime
                  ? this.calcTimeInMin(spec.questions[quesIndex].time)
                    / this.modelDistData.totalTime
                  : 0))
              * (this.examData?.exam?.examInfo?.totalDegree || 1)
              * (distribution.time && distribution.difficulty ? 0.5 : 1)
            ).toFixed(this.fractionNumber),
          );
        }

        if (spec.type === this.questionTypesKeys.MATCHING.id) {
          this.reCalculateSubQuestionsDegrees(
            spec.questions[quesIndex].matchingSubQuestions,
            spec.questions[quesIndex].degree,
          );
          spec.questions[quesIndex].degree = this.reCalculateTotalDegreeFromSubQuestions(
            spec.questions[quesIndex].matchingSubQuestions,
          );
        } else if (spec.type === this.questionTypesKeys.CASE_STUDY.id) {
          this.reCalculateSubQuestionsDegrees(
            spec.questions[quesIndex].caseStudySubQuestions,
            spec.questions[quesIndex].degree,
          );
          spec.questions[quesIndex].degree = this.reCalculateTotalDegreeFromSubQuestions(
            spec.questions[quesIndex].caseStudySubQuestions,
          );
        }
      });
    });
    /* eslint-enable no-param-reassign */
  }

  formalizeModel() {
    return this.filteredModelSpecs.map((spec, specIndex) => ({
      header: spec.header,
      type: spec.type,
      order: specIndex,
      numberOfAnswers: spec.numberOfAnswers,
      questions: spec.questions.map((ques) => ({
        id: ques.questionId || ques.id,
        answersCount: ques.answers ? ques.answers.length : 0,
        typeId: ques.typeId,
        degree: Number(ques.degree),
        time: ques.time,
        matchingSubQuestions: ques.matchingSubQuestions,
        caseStudySubQuestions: ques.caseStudySubQuestions,
        notChanged: ques.notChanged,
        updatedAt: ques.updatedAt,
      })),
    }));
  }

  async simulateExam() {
    this.model.specs = this.specs;
    this.showExamPaperDialog = true;
  }

  async getModelSimilarity() {
    try {
      this.loadingModelSimilarity = true;
      this.formalizedModel = this.formalizeModel();
      const reqQuery = this.isEditMode ? { excludeModelNo: this.modelNo } : {};
      const similarityData: SimilarityData = await ExamProvider.getModelSimilarity(
        this.appId,
        this.courseId,
        this.examId,
        reqQuery,
        { modelSpecs: this.formalizedModel },
      );
      this.loadingModelSimilarity = false;

      if (similarityData.modelNo === null) {
        this.saveModel();
      } else {
        this.similarityData = similarityData;
        this.showSimilarityDialog = true;
      }
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  validatePanelsId(question) {
    if (question.typeId === this.questionTypesKeys.MATCHING.id) {
      const subQuestions = question.matchingSubQuestions.filter(
        (sub) => !sub.degree
          || sub.degree <= 0
          || (sub.degree.toString().split('.')[1]
            && sub.degree.toString().split('.')[1].length > 2),
      );

      return subQuestions.length > 0;
    }
    if (question.typeId === this.questionTypesKeys.CASE_STUDY.id) {
      const subQuestions = question.caseStudySubQuestions.filter(
        (sub) => !sub.degree
          || sub.degree <= 0
          || (sub.degree.toString().split('.')[1]
            && sub.degree.toString().split('.')[1].length > 2),
      );

      return subQuestions.length > 0;
    }
    if (
      question.degree
      && question.degree > 0
      && (!question.degree.toString().split('.')[1]
        || (question.degree.toString().split('.')[1]
          && question.degree.toString().split('.')[1].length <= 2))
    ) {
      return false;
    }
    return true;
  }

  saveModel() {
    this.saveLoading = true;
    if (this.isCopyMode) {
      this.copyModel();
    } else if (this.isEditMode) {
      this.editModel();
    } else {
      this.addModel();
    }
  }

  async addModel() {
    try {
      const {
        message,
        modelNumber,
      }: {
        modelNumber: number;
        message: Lang;
      } = await ExamProvider.addExamModel(
        this.appId,
        this.courseId,
        this.examId,
        {
          modelSpecs: this.formalizedModel,
        },
      );
      this.successToaster(message, { duration: 2000 });
      setTimeout(() => {
        this.$router.push({
          name: 'model-details',
          params: {
            examId: this.examId,
            courseId: this.courseId,
            modelNumber: modelNumber.toString(),
          },
        });
      }, 2000);
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.saveLoading = false;
      this.showSimilarityDialog = false;
    }
  }

  async editModel() {
    try {
      const { message }: { message: Lang } = await ExamProvider.editExamModel(
        this.appId,
        this.courseId,
        this.examId,
        this.modelNo as string,
        { modelSpecs: this.formalizedModel },
      );
      this.successToaster(message, { duration: 2000 });
      setTimeout(() => {
        this.$router.push({
          name: 'model-details',
          params: {
            examId: this.examId,
            courseId: this.courseId,
            modelNumber: this.modelNo.toString(),
          },
        });
      }, 2000);
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.saveLoading = false;
      this.showSimilarityDialog = false;
    }
  }

  async copyModel() {
    try {
      const {
        message,
        modelNumber,
      }: {
        modelNumber: number;
        message: Lang;
      } = await ExamProvider.copyExamModel(
        this.appId,
        this.courseId,
        this.examId,
        this.modelNo,
        {
          shuffle: this.shuffle,
          shuffleKey: this.shuffleKey,
          modelSpecs: this.formalizedModel,
        },
      );
      this.successToaster(message, { duration: 2000 });
      setTimeout(() => {
        this.$router.push({
          name: 'model-details',
          params: {
            examId: this.examId,
            courseId: this.courseId,
            modelNumber: modelNumber.toString(),
          },
        });
      }, 2000);
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.saveLoading = false;
      this.showSimilarityDialog = false;
    }
  }

  goBack() {
    if (this.isEditMode) {
      this.$router.push({ path: `/exam-generation/show_model/${this.courseId}/${this.examId}/${this.modelNo}` });
    } else {
      this.$router.push({ path: `/exam-generation/${this.courseId}/${this.examId}` });
    }

  //  this.$router.go(-1);
  }

  openSpecTypeModel() {
    this.$refs.SpecTypeDialog.open();
  }

  openQuestionsModel({ questionType, answersCount, SpecName }) {
    this.$refs.AddQuestionDialog.open(
      true,
      questionType,
      answersCount,
      SpecName,
      this.specs?.length,
    );
  }

  appendspec(newSpec) {
    if (this.specs) {
      this.specs.push(newSpec);
      const ids = newSpec.questions.map((item) => item.id);
      this.selectedQuestionsIds = [...this.selectedQuestionsIds, ...ids];
      this.openPanels.push(this.specs.length - 1);
      setTimeout(() => {
        this.renderEquations();
      }, 0);
    }
  }

  appendQuestions({ newQuestions, index }) {
    if (this.specs) {
      this.specs[index].questions.push(...newQuestions);
      const ids = newQuestions.map((item) => item.id);
      this.selectedQuestionsIds = [...this.selectedQuestionsIds, ...ids];
    }
    setTimeout(() => {
      this.renderEquations();
    }, 0);
  }

  deletespec(index) {
    if (this.specs) {
      this.specs.splice(index, 1);
      const expanded: number[] = [];
      for (let i = 0; i < this.specs.length; i += 1) {
        expanded.push(i);
      }
      setTimeout(() => {
        this.openPanels = expanded;
        this.renderEquations();
      }, 0);
    }
  }
}
