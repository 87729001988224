































































import { Component, Vue, Prop } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import { QuestionTypes } from '../../types/exam.type';
import { APPIDS, QUESION_TYPES } from '../../enums';
import { Lang } from '../../types/general.type';

@Component({
  components: {
    DialogComponent,
  },
})
export default class SpecTypeDialogComponent extends Vue {
  showSpecTypeDialog = false;

  questionType = '';

  answersCount = '';

  questionTypesKeys = QUESION_TYPES;

  countOfAnswers = [3, 4, 5];

  examId = this.$route.params.examId;

  courseId = this.$route.params.courseId;

  appId = APPIDS.EXAM_GENERATION;

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly questionTypes!: QuestionTypes[];

  loading = false;

  error: Lang | null = null;

  get disableQuestionTypeConfirm() {
    if (!this.questionType) {
      return true;
    }
    if (
      (this.questionType === this.questionTypesKeys.MMCQ.id
        || this.questionType === this.questionTypesKeys.MCQ.id)
      && !this.answersCount
    ) {
      return true;
    }
    return false;
  }

  get newQuestionTypes() {
    return this.types?.map((type: QuestionTypes) => ({
      translatedName: this.$options.filters?.servertranslate(type.baName),
      ...type,
    }));
  }

  get types() {
    return this.questionTypes;
  }

  open() {
    this.showSpecTypeDialog = true;
  }

  confirmDialog() {
    const name = this.types?.find(
        (item: QuestionTypes) => item.baID === this.questionType
      ) || {};
    this.$emit('openQuestionsModel', {
      questionType: this.questionType,
      answersCount: this.answersCount,
      SpecName: name.baName,
    });

    this.close();
  }

  close() {
    this.showSpecTypeDialog = false;
    this.questionType = '';
    this.answersCount = '';
  }
}
