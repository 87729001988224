
















































































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import DialogComponent from '../dialog.component.vue';
import {
  APPIDS, QUESION_TYPES, SUPPORTED_LANGS, VALID_DIFFICULTY_RANGE, VALID_TIME_RANGE,
} from '../../enums';
import { QuestionTypeKeyFunction } from '../../types/questions.type';
import examProvider from '../../providers/exam.provider';
import ViewMatchingColumns from '../questions/matching/viewMatchingColumns.vue';

import {
  DifficultyExamType,
  FetchExamDataRes,
  SpecAddFilters,
  ExamIlos,
  ExamSubjects,
  SpecQuestion,
} from '../../types/exam.type';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import ViewQuestionDetails from './ViewQuestionDetails.component.vue';

@Component({
  components: {
    DialogComponent,
    ViewMatchingColumns,
    ViewQuestionDetails,
  },
})
export default class AddQuestionDialogComponent extends MathJaxMixin {
  headerClass = 'grey darken-4 white--text pa-3 qb-table__header';

  questionTypesKeys = QUESION_TYPES;

  loading = false;

  error = '';

  questionsList: SpecQuestion[] = [];

  valid = true;

  showQuestionAddDialog = false;

  @Prop() readonly usedQuestions!: [];

  @Prop() readonly examDirection!: string;

  @Prop({ default: '' }) readonly examLangId!: string;

  examId = this.$route.params.examId;

  courseId = this.$route.params.courseId;

  appId = APPIDS.EXAM_GENERATION;

  selected = [];

  expanded = [];

  expandedSubQuestions = {};

  subjects: ExamSubjects[] | undefined = [];

  ilos: ExamIlos[] | undefined = [];

  difficulty: DifficultyExamType[] | undefined = [];

  order = 0;

  SpecType = '';

  questionTitle = {};

  answersCount = '';

  newSpec = true;

  index = 0;

  totalCount = 0;

  positiveValidation = [
    (val: number) => val >= 0 || this.$t('validations.NEGATIVE_NUMBER'),
  ];

  integerValidations = [
    ...this.positiveValidation,
    (val: number) => Number(val) === Math.round(val) || this.$t('validations.integerValue'),
  ];

  exposureLimitRules = [
    (v: number) => (!v || v >= 1) || `${this.$t('minValue')} 1 `,
    (v: number) => (!v || v <= 999) || `${this.$t('maxValue')}  999 `,
    (v: number) => (!v || Number(v) === Math.round(v)) || this.$t('validations.integerValue'),
  ];

  difficultyNeedToFix(item): boolean {
    if (item && item.typeId === QUESION_TYPES.CASE_STUDY.id) {
      const subQuestionsCount = item?.subQuestions?.length as number;
      for (let i = 0; i < subQuestionsCount; i += 1) {
        const averageDifficulty = item?.subQuestions[i]?.averageDifficulty as number;
        const difficulty = item?.subQuestions[i]?.difficulty as number;
        const topDifficulty = difficulty ? difficulty + difficulty * VALID_DIFFICULTY_RANGE : VALID_DIFFICULTY_RANGE;
        const bottomDifficulty = difficulty ? difficulty - difficulty * VALID_DIFFICULTY_RANGE : 0;
        if ((!!averageDifficulty || averageDifficulty === 0)
          && (topDifficulty < averageDifficulty || bottomDifficulty > averageDifficulty)) {
          return true;
        }
      }
      return false;
    }
    const difficulty = item?.difficulty as number;
    const averageDifficulty = item?.averageDifficulty as number;
    const topDifficulty = difficulty ? difficulty + difficulty * VALID_DIFFICULTY_RANGE : VALID_DIFFICULTY_RANGE;
    const bottomDifficulty = difficulty ? difficulty - difficulty * VALID_DIFFICULTY_RANGE : 0;
    return (!!averageDifficulty || averageDifficulty === 0)
      && (topDifficulty < averageDifficulty || bottomDifficulty > averageDifficulty);
  }

  timeNeedToFix(item): boolean {
    if (item.typeId === QUESION_TYPES.CASE_STUDY.id) {
      const subQuestionsCount = item?.subQuestions?.length as number;
      for (let i = 0; i < subQuestionsCount; i += 1) {
        const time = item?.subQuestions[i]?.timeInMinutes as number;
        const averageTime = item?.subQuestions[i]?.averageTime as number;
        const topTime = time ? time + time * VALID_TIME_RANGE : VALID_TIME_RANGE;
        const bottomTime = time ? time - time * VALID_TIME_RANGE : 0;
        if ((!!averageTime || averageTime === 0) && (topTime < averageTime || bottomTime > averageTime)) {
          return true;
        }
      }
      return false;
    }
    const time = item?.timeInMinutes as number;
    const averageTime = item?.averageTime as number;
    const topTime = time ? time + time * VALID_TIME_RANGE : VALID_TIME_RANGE;
    const bottomTime = time ? time - time * VALID_TIME_RANGE : 0;
    return (!!averageTime || averageTime === 0) && (topTime < averageTime || bottomTime > averageTime);
  }

  specAddFilters: SpecAddFilters = {
    pageNo: 0,
    searchFilters: {
      subjectId: '',
      difficultyId: '',
      degree: {
        degreeFrom: '',
        degreeTo: '',
      },
      time: {
        timeFrom: '',
        timeTo: '',
      },
      title: '',
      ilo: '',
      exposureLimitLessThan: null,
      useQuestionsNeedUpdate: true,
    },
  };

  get specDifficulty() {
    return this.difficulty?.map((item) => ({
      translatedName: this.$options.filters?.servertranslate(item.name),
      ...item,
    }));
  }

  get specIlos() {
    return this.ilos?.map((item) => ({
      translatedName: this.$options.filters?.servertranslate(item.iloName),
      ...item,
    }));
  }

  async getQuestions(more?) {
    this.specAddFilters.examId = this.examId;
    this.specAddFilters.questionType = this.SpecType;
    this.specAddFilters.answersCount = this.answersCount;
    this.specAddFilters.usedQuestions = this.usedQuestions;
    this.loading = true;
    this.specAddFilters.pageNo = more ? this.specAddFilters?.pageNo + 1 : 0;
    this.selected = more ? this.selected : [];
    try {
      const res = await examProvider.getSpecQuestions(
        this.appId,
        this.courseId,
        this.specAddFilters,
      );
      if (more) {
        this.questionsList.push(...res.questions);
      } else {
        this.questionsList = [...res.questions];
      }
      this.expandAllSubQuestions();
      this.totalCount = res.totalCount;

      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.error = err;
    }
  }

  get headers() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionTitle'),
        sortable: false,
        value: 'questionTitle',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.ILOs'),
        sortable: false,
        value: 'ILO',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionType'),
        sortable: false,
        align: 'center',
        value: 'questionType',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.subject'),
        sortable: false,
        align: 'center',
        value: 'subject',
        class: this.headerClass,
      },
    ];
  }

  close() {
    this.selected = [];
    this.expanded = [];
    this.questionsList = [];
    this.clearSearch();
    this.index = 0;
    this.SpecType = '';
    this.answersCount = '';
    this.questionTitle = '';
    this.order = 0;
    this.totalCount = 0;
    this.showQuestionAddDialog = false;
  }

  async open(newSpec, SpecType, answersCount, questionTitle?, order?, index?) {
    this.index = index;
    this.newSpec = newSpec;
    this.showQuestionAddDialog = true;
    this.loading = true;
    this.SpecType = SpecType;
    this.questionTitle = questionTitle;
    this.answersCount = answersCount;
    this.order = order;

    try {
      const res: FetchExamDataRes = await examProvider.fetchExamData(
        this.appId,
        this.courseId,
        this.examId,
        {},
      );

      this.difficulty = res.examData.difficultyLevelRanges;
      this.subjects = res.examData.subjects;

      this.ilos = res.examData.ilos;
      this.getQuestions();

      this.loading = false;
    } catch (err) {
      this.loading = false;

      this.error = err;
    }
  }

  clearSearch() {
    const page = this.specAddFilters.pageNo;
    this.specAddFilters = {
      pageNo: page,

      searchFilters: {
        subjectId: '',
        difficultyId: '',
        degree: {
          degreeFrom: '',
          degreeTo: '',
        },
        time: {
          timeFrom: '',
          timeTo: '',
        },
        title: '',
        ilo: '',
        exposureLimitLessThan: null,
        useQuestionsNeedUpdate: true,
      },
    };
  }

  confirmDialog() {
    if (this.newSpec) {
      const newSpec = {
        header:
          (this.examLangId === SUPPORTED_LANGS.AR
            ? (QUESION_TYPES[this.SpecType] as QuestionTypeKeyFunction)()
                ?.defaultText?.ar
            : (QUESION_TYPES[this.SpecType] as QuestionTypeKeyFunction)()
                ?.defaultText?.en) || '',
        order: this.order,
        numberOfAnswers: this.answersCount,
        type: this.SpecType,
        questions: this.selected,
        countOfAnswers: this.answersCount,
        countOfQuestions: this.selected.length,
        questionType: this.SpecType,
        title: this.$options.filters?.servertranslate(this.questionTitle),
        validPanel: true,
      };
      this.$emit('addSpec', newSpec);
    } else {
      this.$emit('appendQuestions', {
        newQuestions: this.selected,
        index: this.index,
      });
    }
    this.close();
  }

  expandAllSubQuestions() {
    const currentValue = { ...this.expandedSubQuestions };
    this.expandedSubQuestions = this.questionsList.reduce((acc, question) => {
      if (question.id && !currentValue[question.id] && question.subQuestions && question.subQuestions.length) {
        acc[question.id] = Array.from(Array(question.subQuestions.length).keys());
      }
      return acc;
    }, { ...this.expandedSubQuestions });
  }

  onExpand(event: { value: boolean }) {
    // Renders MathJax Equations
    if (event.value) {
      setTimeout(() => {
        this.renderEquations();
      }, 0);
    }
  }
}
