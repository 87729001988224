








































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';

import { mixins } from 'vue-class-component';
import UTILS from '@/shared/utils';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import { Exam, LabelsConfig, Model } from '../../types/exam.type';
import { QUESION_TYPES, ANSWER_CHOICES } from '../../enums';
import ViewMatchingColumns from '../questions/matching/viewMatchingColumns.vue';
import MatchingViewPaper from './matchingViewPaper.component.vue';
import CaseStudyViewModelPaper from './CaseStudyViewModelPaper.component.vue';

@Component({
  components: {
    ViewMatchingColumns,
    MatchingViewPaper,
    CaseStudyViewModelPaper,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ExamModelPaperComponent extends mixins(MathJaxMixin) {
  @Prop() readonly exam!: Exam;

  @Prop() readonly labelsConfig!: LabelsConfig;

  @Prop() readonly model!: Model;

  loaded = false;

  questionTypesKeys = QUESION_TYPES;

  get questionLabel() {
    return {
      ar: ' السؤال رقم',
      en: 'Question Num ',
    };
  }

  get markLabel() {
    return {
      ar: 'درجة',
      en: 'Marks',
    };
  }

  get courseCodeLabel() {
    return {
      ar: 'كود المقرر:',
      en: 'Course Code:',
    };
  }

  get examSpecs() {
    return this.model.specs ? this.model.specs : [];
  }

  get courseCode() {
    return this.exam.course && this.labelsConfig.courseCode
      ? this.exam.course.courseCode
      : null;
  }

  getSpecTotalDegree(spec) {
    return Number(
      spec.questions
        .reduce((acc, question) => acc + question.degree, 0)
        .toFixed(2),
    );
  }

  get examSignature() {
    return this.exam.examInfo ? this.exam.examInfo.examSignature : null;
  }

  get examLang() {
    return this.exam.examInfo ? this.exam.examInfo.examLang : null;
  }

  get examTypeName() {
    if (this.exam.examInfo && this.labelsConfig.examType) {
      return this.exam.examInfo.examTypeName[this.examLangCode];
    }
    return '';
  }

  get degreeLabel() {
    return {
      ar: 'الدرجة:',
      en: 'Degree: ',
    };
  }

  get modelNumberLabel() {
    return {
      ar: 'نموذج رقم:',
      en: 'Model Number:',
    };
  }

  get modelNum() {
    return this.labelsConfig.modelNumber
      ? this.$route?.params.modelNumber
      : null;
  }

  get examDegree() {
    return this.exam.examInfo && this.labelsConfig.examDegree
      ? this.exam.examInfo.totalDegree
      : null;
  }

  get examDate() {
    return this.exam.examInfo && this.labelsConfig.examDate
      ? this.exam.examInfo.examDate
      : null;
  }

  getArabicMonthsNames() {
    return [
      'يناير',
      'فبراير',
      'مارس',
      'ابريل',
      'مايو',
      'يونيو',
      'يوليو',
      'اغسطس',
      'سبتمير',
      'اكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
  }

  get localizedExamDate() {
    const DateString = new Date(this.examDate as string);
    if (!DateString) {
      return null;
    }
    const monthindex = DateString.getMonth();
    const monthEn = DateString.toLocaleString('default', { month: 'long' });
    const year = DateString.getFullYear();
    if (this.examLangCode === 'ar') {
      return `${this.getArabicMonthsNames()[monthindex]} ${year}`;
    }
    return ` ${monthEn} ${year}`;
  }

  get courseInstructorLabel() {
    return this.servertranslate({
      ar: 'استاذ المادة:',
      en: 'Course Instructor: ',
    });
  }

  get answerChoices() {
    return ANSWER_CHOICES;
  }

  get facultyLogo() {
    return this.exam.examInfo
      && this.exam.examInfo.logo
      && this.labelsConfig.logo
      ? `data:image/png;base64,${this.exam.examInfo.logo}`
      : null;
  }

  onLoaded() {
    this.loaded = true;
  }

  get courseName() {
    return this.exam.course && this.labelsConfig.courseName
      ? this.langText(this.exam?.course?.courseName)
      : null;
  }

  get paperDirection() {
    return this.examLang === '4.2.' ? 'ltr' : 'rtl';
  }

  tranlate(str) {
    return this.examLang === '4.2.' ? str.split('|')[1] : str.split('|')[0];
  }

  get minutesLabel() {
    return {
      ar: 'دقيقة',
      en: 'minutes',
    };
  }

  servertranslate(field) {
    return this.examLang === '4.2.' ? field.en : field.ar;
  }

  get examTime() {
    return this.exam.examInfo && this.labelsConfig.examTime
      ? this.exam.examInfo.totalTime
      : null;
  }

  get timeLabel() {
    return {
      ar: 'الوقت:',
      en: 'Time: ',
    };
  }

  get examTitle() {
    return this.exam.examInfo ? this.exam.examInfo.examTitle : null;
  }

  get titleLabel() {
    return {
      ar: 'عنوان الاختبار:',
      en: 'Exam Title: ',
    };
  }

  get batchName() {
    return this.exam.course
      ? this.servertranslate(this.exam?.course?.scopeName)
        .split('-')
        .slice(-1)[0]
      : null;
  }

  get semesterName() {
    return this.exam?.examInfo && this.labelsConfig?.semester
      ? this.exam?.examInfo?.semesterName[this.examLangCode]
      : null;
  }

  get dateLabel() {
    return {
      ar: 'تاريخ:',
      en: 'Date:',
    };
  }

  get majorName() {
    return this.exam.course
      ? this.langText(this.exam?.course?.scopeName).split('-').slice(-2)[0]
      : null;
  }

  get courseInstructor() {
    return this.exam.examInfo
      && this.exam.examInfo.instructorData?.id
      && this.labelsConfig.courseInstructor
      ? this.langText(this.exam.examInfo.instructorData?.name)
      : null;
  }

  setElementHeader(paperElm) {
    const headerFontSize = '18px';
    const pageHeader = paperElm.querySelector('.paper-header');
    const newPageHeaderElm = document.createElement('table');
    const newRowElm = document.createElement('tr');
    const inlineElms = paperElm.querySelectorAll('.export-header-part');
    [].forEach.call(inlineElms, (inlineElm, index) => {
      const newColElm = document.createElement('td');
      newColElm.style.fontSize = headerFontSize;
      newColElm.style.fontWeight = 'bold';
      newColElm.style.width = '500px';
      if (index === 1) {
        newColElm.innerHTML = (inlineElm as any).innerHTML;
        const logo = (inlineElm as any).querySelector('img');
        if (this.loaded) {
          // logo
          newColElm.style.width = '300px';
          newColElm.style.textAlign = 'center';
          logo.style.maxWidth = '70%;';
          newColElm.innerHTML = logo.outerHTML;
        }
      } else if ((inlineElm as any).getElementsByTagName('div').length > 0) {
        const tags = (inlineElm as any).getElementsByTagName('div');

        for (let i = 0; i < tags.length; i += 1) {
          newColElm.append(tags[i].innerHTML);
          newColElm.append(document.createElement('br'));
        }
      }
      newRowElm.appendChild(newColElm);
    });
    newPageHeaderElm.appendChild(newRowElm);
    pageHeader.parentNode.replaceChild(newPageHeaderElm, pageHeader);
    newPageHeaderElm.insertAdjacentElement(
      'afterend',
      document.createElement('hr'),
    );
    return paperElm;
  }

  convertImagesToBase64(paperElm) {
    const regularImages = paperElm.querySelectorAll('img');
    const canvas = document.createElement('canvas');
    const resizedCanvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const resizedCTX = resizedCanvas.getContext('2d');
    [].forEach.call(regularImages, (imgElement) => {
      if (ctx == null) console.log('null');
      else ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (resizedCTX == null) console.log('null');
      else {
        resizedCTX.clearRect(0, 0, resizedCanvas.width, resizedCanvas.height);
      }
      canvas.width = (imgElement as any).width;
      canvas.height = (imgElement as any).height;

      resizedCanvas.width = (imgElement as any).width > 150 ? 150 : (imgElement as any).width;

      resizedCanvas.height = (imgElement as any).height > 150 ? 150 : (imgElement as any).height;
      const img = new Image();
      img.src = (imgElement as any).src;
      // img.crossOrigin = "anonymous";

      img.onload = () => {
        (ctx as any).drawImage(
          imgElement,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          resizedCanvas.width,
          resizedCanvas.height,
        );
        (resizedCTX as any).drawImage(canvas, 0, 0);
        const dataURL = resizedCanvas.toDataURL();
        (imgElement as any).setAttribute('src', dataURL);
      };
    });
    resizedCanvas.remove();
    canvas.remove();
  }

  stylingSpecs(justifiedPaperElmHtml) {
    const fontSize = '18px';
    const specsElms = justifiedPaperElmHtml?.querySelectorAll('.spec');
    [].forEach.call(specsElms, (specsElm) => {
      const specHeaderElm = (specsElm as any).querySelector('.spec-header');
      specHeaderElm.style.fontSize = fontSize;
      specHeaderElm.style.fontWeight = 'bold';
      specHeaderElm.style.textDecoration = 'underline';

      const questionHeaderElms = (specsElm as any).querySelectorAll(
        '.itemHeader',
      );
      [].forEach.call(questionHeaderElms, (questionHeaderElm) => {
        const newHeaderElm = document.createElement('table');
        newHeaderElm.style.width = '100%';
        // newHeaderElm.style['word-break'] = 'break-all';
        const newTRowElm = document.createElement('tr');
        const inlineElms = (questionHeaderElm as any).querySelectorAll(
          '.itemHeaderDetails',
        );

        [].forEach.call(inlineElms, (inlineElm, inlineElmIndex) => {
          const newColElm = document.createElement('td');
          if (inlineElmIndex === 0 && (inlineElm as any).style.display !== 'none') {
            newColElm.innerHTML = (inlineElm as any).innerHTML;
            const paraElm = document.createElement('strong');
            paraElm.innerHTML = newColElm.children[0]?.innerHTML;
            paraElm.style.unicodeBidi = 'embed';
            paraElm.style.direction = 'ltr';
            paraElm.style.textAlign = 'center';
            paraElm.style.fontSize = fontSize;
            newColElm.style.width = '40px';
            newColElm.style.verticalAlign = 'top';
            newColElm.replaceChild(paraElm, newColElm.children[0]);
          } else if (inlineElmIndex >= 1 && inlineElmIndex <= 2) {
            newColElm.innerHTML = (inlineElm as any).innerText;
            newColElm.style.fontSize = fontSize;
            newColElm.style.fontSize = fontSize;

            if (newColElm.getElementsByTagName('img').length > 0) {
              if (newColElm.getElementsByTagName('img')[0] !== undefined) {
                newColElm.getElementsByTagName('img')[0].style.maxWidth = '100%';
                newColElm.getElementsByTagName('img')[0].style.maxHeight = '100%';
              }
            }
            if (newColElm.getElementsByTagName('p').length > 0) {
              if (newColElm.getElementsByTagName('p')[0] !== undefined) {
                newColElm.getElementsByTagName('p')[0].style.fontSize = fontSize;
              }
            }
          }
          newTRowElm.appendChild(newColElm);
        });
        newHeaderElm.appendChild(newTRowElm);
        (questionHeaderElm as any).parentNode.replaceChild(
          newHeaderElm,
          questionHeaderElm,
        );
      });

      const questionsElms = (specsElm as any).querySelectorAll(
        '.question-item',
      );
      [].forEach.call(questionsElms, (questionsElm) => {
        const containingAnsElms = (questionsElm as any).querySelectorAll(
          '.answer',
        );
        const newAnsElmDtails = document.createElement('table');
        const newAnsTRowElmDtails = document.createElement('tr');
        [].forEach.call(containingAnsElms, (containingAnsElm) => {
          [].forEach.call(
            (containingAnsElm as any).children,
            (inlineAnsElm, inlineAnsElmIndex) => {
              const newAnsColElm = document.createElement('td');
              newAnsColElm.innerHTML = (inlineAnsElm as any).innerHTML;
              if (inlineAnsElmIndex === 0) {
                const paraElm = document.createElement('p');
                paraElm.innerHTML = newAnsColElm.children[0].innerHTML;
                newAnsColElm.style.width = '50px';
                newAnsColElm.style.verticalAlign = 'top';
                newAnsColElm.style.fontWeight = 'bold';
                paraElm.style.unicodeBidi = 'embed';
                paraElm.style.fontSize = fontSize;
                paraElm.style.direction = 'ltr';
                paraElm.style.textAlign = 'center';
                newAnsColElm.replaceChild(paraElm, newAnsColElm.children[0]);
              } else {
                newAnsColElm.style.fontSize = fontSize;
                // const inlineElms = (newAnsColElm as any).querySelectorAll('span');
                const inlineElms = (newAnsColElm as any).querySelectorAll('td');
                if (inlineElms.length > 2) {
                  const paraElmEq = document.createElement('span');
                  paraElmEq.appendChild(inlineElms[1]);
                  paraElmEq.appendChild(inlineElms[2]);
                  newAnsColElm.innerHTML = (paraElmEq as any).innerHTML;
                } else {
                  if (newAnsColElm.getElementsByTagName('img').length) {
                    newAnsColElm.getElementsByTagName('img')[0].style.maxWidth = '100%';

                    newAnsColElm.getElementsByTagName('img')[0].style.maxHeight = '100%';
                  }
                  if (newAnsColElm.getElementsByTagName('p').length > 0) {
                    const tags = newAnsColElm.getElementsByTagName('p');

                    for (let i = 0; i < tags.length; i += 1) {
                      tags[i].style.fontSize = fontSize;
                    }
                  }
                }
              }
              newAnsTRowElmDtails.appendChild(newAnsColElm);
            },
          );

          newAnsElmDtails.appendChild(newAnsTRowElmDtails);
          (containingAnsElm as any).parentNode.replaceChild(
            newAnsElmDtails,
            containingAnsElm,
          );
        });
      });

      (specsElm as any).insertAdjacentElement(
        'afterend',
        document.createElement('hr'),
      );
      const signatureElem = justifiedPaperElmHtml.querySelector('.signature');
      signatureElem.style.fontSize = fontSize;
      signatureElem.style.fontWeight = 'bold';
      signatureElem.style.textAlign = 'center';
    });
  }

  async exportExam(pdf = false) {
    const paperElm = document?.querySelector('.model-paper');
    const tempElm = document?.createElement('div');
    tempElm.innerHTML = paperElm?.innerHTML as string;
    const justifiedPaperElmHtml = this.setElementHeader(tempElm);
    this.stylingSpecs(justifiedPaperElmHtml);

    const paperElmHtml = justifiedPaperElmHtml.innerHTML;
    const oldHead = document.head.innerHTML;
    const htmlToExport = `
        <!DOCTYPE html>
        <html
          dir='${this.paperDirection}'
          lang='${this.examLangCode}'
          xml:lang="${this.examLangCode}"
          xmlns:o='urn:schemas-microsoft-com:office:office'
          xmlns:w='urn:schemas-microsoft-com:office:word'
          xmlns='http://www.w3.org/TR/REC-html40'
        >
          <head>
          ${oldHead}
            <meta charset="UTF-8" />
            <title> ${this.examTitle || 'exam'} </title>
          </head>
          <body>
            ${paperElmHtml}
          </body>
        </html>
      `;
    if (pdf) {
      UTILS.DOWNLOAD_EXAM_PDF(htmlToExport, this.paperDirection, this.examLangCode, this.modelNum, this.examTitle);
    } else {
      asBlob(htmlToExport).then((data) => {
        saveAs(data, `${this.examTitle || 'exam'}.docx`); // save as docx file
      });
    }
  }

  printExam() {
    const paperElm = document?.querySelector('.model-paper');
    const tempElm = document?.createElement('div');
    tempElm.innerHTML = paperElm?.innerHTML as string;
    const justifiedPaperElmHtml = this.setElementHeader(tempElm);
    this.convertImagesToBase64(justifiedPaperElmHtml);
    this.stylingSpecs(justifiedPaperElmHtml);
    const paperElmHtml = justifiedPaperElmHtml.innerHTML;
    UTILS.PRINT_EXAM(paperElmHtml, this.paperDirection, this.examLangCode);
  }

  get examLangCode() {
    return this.examLang === '4.2.' ? 'en' : 'ar';
  }

  get universityName() {
    return this.exam.course && this.labelsConfig.universityName
      ? this.langText(this.exam.course?.scopeName).split('-')[0]
      : null;
  }

  get facultyName() {
    return this.exam?.course && this.labelsConfig?.facultyName
      ? this.langText(this.exam.course?.scopeName).split('-')[1]
      : null;
  }

  langText(text) {
    return text[this.examLangCode];
  }

  hideQuestionIndex(spec) {
    return spec.type === this.questionTypesKeys.CASE_STUDY.id
      || spec.type === this.questionTypesKeys.MATCHING.id
      || spec.type === this.questionTypesKeys.ESSAY.id;
  }
}
