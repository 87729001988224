









/* eslint-disable*/

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  useTheme,
  create,
  color,
  percent,
  Label,
  MouseCursorStyle,
  LinePattern,
  Scrollbar,
  ExportMenu,
  Container,
  Align,

} from "@amcharts/amcharts4/core";
import {
  XYChart,
  Legend,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  LabelBullet,
  XYCursor,
  Column,
} from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  LabelBulletObject,
  DataFields,
  LegendObject,
  CustomCategory,
  DataObject,
} from "../types/barChart.type";

useTheme(am4themes_animated);

@Component
export default class BarChartComponent extends Vue {
  [x: string]: any;

  @Prop() data!: DataObject[];

  @Prop() customTooltip!: boolean;

  @Prop() legend!: boolean;

  @Prop() customLegendObject!: LegendObject;

  @Prop() menuItem!: boolean;

  @Prop({ default: "right" }) menuItemPostion!: Align;

  @Prop() scroll!: boolean;

  @Prop({
    default: () => ({
      split: 50,
      firstColor: "#13ad53",
      secondColor: "#a8b3b7",
    }),
  })
  customCategory!: CustomCategory;

  @Prop() customColors!: boolean;

  @Prop() columnBackground!: boolean;

  @Prop() strokeColor!: boolean;

  @Prop() categorize!: boolean;

  @Prop() axisLabel!: boolean;

  @Prop() yAxisLabel!: string;

  @Prop() xAxisLabel!: string;

  @Prop({ default: 0 }) index!: number;

  @Prop() title!: string;

  @Prop() dataFields!: DataFields;

  @Prop() strictMaxY!: number;

  @Prop() scrollType!:
    | "zoomX"
    | "zoomY"
    | "zoomXY"
    | "selectX"
    | "selectY"
    | "selectXY"
    | "panX"
    | "panY"
    | "panXY"
    | "none";

  @Prop() labelBreak!: number;

  @Prop() labelMaxWidth!: number;

  @Prop() labelBulletObject!: LabelBulletObject;

  @Prop() strictMinY!: number;
  @Prop() disableXLabel!: boolean;
  @Prop() maxPrecision!: boolean;

  @Watch("lang", { deep: true })
  onPropertyChanged() {
    this.drawChart();
  }

  $refs!: {
    chartdiv: HTMLElement;
  };

  private chart!: XYChart;

  get lang() {
    return this.$store.state.lang.lang;
  }

  @Watch("data", { deep: true })
  onSelectionChange() {
    this.drawChart();
  }

  drawChart() {
    // create chartContainer
    const chart = create(this.$refs.chartdiv, XYChart);

    // Add Custom Colors
    if (this.customColors) {
      this.data = this.data.map((column) => ({
        ...column,
        color: color(column.color),
      }));
    }
    // pass chart data
    chart.data = this.data;
    chart.rtl = this.lang === "ar";

    // Create Axis Label Container
    if (this.axisLabel) {
      this.createAxisLabels(chart);
    }

    // Create axes
    this.createAxes(chart);

    // create series
    const { series, columnTemplate } = this.createSeries(chart);

    // create custom legend
    if (this.customLegendObject) {
      this.createLegend(chart, this.customLegendObject, series);
    }

    // ColorColumns By Category
    if (this.columnBackground) {
      this.colorColumns(chart, series, columnTemplate);
    }
    // else {
    //   this.defaultColorColumns(chart, columnTemplate);
    // }

    if (this.labelBulletObject) {
      this.labelBullet(series);
    }
    // Scrolling
    if (this.scroll) {
      this.enableScrolling(chart);
    } else {
      chart.zoomOutButton.disabled = true;
    }
    if (this.menuItem) {
      // Export Menu
      this.setMenuItems(chart);
    }
    // create chart legend
    if (this.legend) {
      chart.legend = new Legend();

     chart.legend.parent = chart.chartContainer;
    chart.legend.itemContainers.template.togglable = true;

    chart.legend.useDefaultMarker = true;

   

    }

    this.chart = chart;
  }

  createLegend(
    chart: XYChart,
    customLegendObject: LegendObject,
    series: ColumnSeries
  ) {
    const legend = new Legend();
    legend.parent = chart.chartContainer;
    legend.itemContainers.template.togglable = true;

    legend.marginTop = customLegendObject.marginTop
      ? customLegendObject.marginTop
      : 0;
    legend.data = customLegendObject.data.map((item) => ({
      name: this.$t(item.name),
      fill: item.fill,
    }));
    legend.useDefaultMarker = true;
    if (customLegendObject.markerRounded) {
      const marker: any = legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      const markerColor = customLegendObject.markerColor
        ? customLegendObject.markerColor
        : "#ccc";
      marker.stroke = color(markerColor);
    }

    if (!customLegendObject.clickable) {
      legend.itemContainers.template.clickable = false;
      legend.itemContainers.template.focusable = false;
      legend.itemContainers.template.cursorOverStyle = MouseCursorStyle.default;
    } else {
      // legend Click Event
      legend.itemContainers.template.events.on("hit", (ev: any) => {
        const { index } = ev.target.dataItem;
        // tslint:disable: no-use-before-declare
        const bulletEvent: any = series.bullets.getIndex(0);
        if (ev.target.isActive) {
          if (index === 1) {
            for (let i = 0; i < series.columns.length; i++) {
              const { value } = series.columns.values[
                i
              ].dataItem!.values.valueY;
              if (value < 50) {
                bulletEvent._clones._values[i].show();
                series.columns.values[i].show();
              }
            }
          } else {
            for (let i = 0; i < series.columns.length; i++) {
              const { value } = series.columns.values[
                i
              ].dataItem!.values.valueY;
              if (value >= 50) {
                bulletEvent._clones._values[i].show();
                series.columns.values[i].show();
              }
            }
          }
        } else if (index === 1) {
          for (let i = 0; i < series.columns.length; i++) {
            const { value } = series.columns.values[i].dataItem!.values.valueY;
            if (value < 50) {
              bulletEvent._clones._values[i].hide();
              series.columns.values[i].visible = false;
            }
          }
        } else {
          for (let i = 0; i < series.columns.length; i++) {
            const { value } = series.columns.values[i].dataItem!.values.valueY;
            if (value >= 50) {
              bulletEvent._clones._values[i].hide();
              series.columns.values[i].visible = false;
            }
          }
        }
      });
    }
  }

  createAxisLabels(chart: XYChart) {
    const topContainer = chart.chartContainer.createChild(Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.width = percent(100);

    // Create YAxis Label
    if (this.yAxisLabel) {
      const axisTitle = topContainer.createChild(Label);
      axisTitle.text = this.yAxisLabel;
      axisTitle.fontWeight = "600";
      axisTitle.align = "left";
      axisTitle.paddingLeft = 0;
    }

    // Create title table
    if (this.title) {
      const dateTitle = topContainer.createChild(Label);
      dateTitle.text = this.title;
      dateTitle.fontWeight = "600";
      dateTitle.align = "center";
    }

    // Craete XAxis label
    if (this.xAxisLabel) {
      const label = chart.createChild(Label);
      label.text = this.xAxisLabel;
      label.fontWeight = "600";
      label.align = "center";
    }
  }

  createAxes(chart: XYChart) {
    const categoryAxis = chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = this.dataFields.category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    // ...

    const label = categoryAxis.renderer.labels.template;
    label.maxWidth = this.labelMaxWidth ? this.labelMaxWidth : 120;
    label.fontSize = 14;

    // label.tooltipText = "{category}";
    if (this.labelBreak) {
      categoryAxis.events.on("sizechanged", (ev) => {
        const axis = ev.target;
        const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);

        if (this.disableXLabel) {
          axis.renderer.labels.template.disabled = true;
        } else if (window.innerWidth < this.labelBreak) {
          axis.renderer.labels.template.rotation = -45;
          axis.renderer.labels.template.horizontalCenter = "right";
          axis.renderer.labels.template.verticalCenter = "middle";
          // axis.renderer.labels.template.truncate = true;
          axis.renderer.labels.template.wrap = false;
        } else {
          axis.renderer.labels.template.rotation = 0;
          axis.renderer.labels.template.horizontalCenter = "middle";
          axis.renderer.labels.template.verticalCenter = "top";
          //axis.renderer.labels.template.truncate = false;
          axis.renderer.labels.template.wrap = true;
        }
      });
    }
    categoryAxis.cursorTooltipEnabled = false;

    const valueAxis = chart.yAxes.push(new ValueAxis());
    if (this.maxPrecision) {
      valueAxis.maxPrecision = 0;
    }
    if (this.strictMaxY) {
      valueAxis.strictMinMax = true;
      valueAxis.max = this.strictMaxY;
    }
    if (this.strictMinY || this.strictMinY === 0) {
      valueAxis.min = this.strictMinY;
    }

    valueAxis.cursorTooltipEnabled = false;
  }

  createSeries(chart: any) {
    const series = chart.series.push(new ColumnSeries());
    series.dataFields.valueY = this.dataFields.value;
    series.dataFields.categoryX = this.dataFields.category;
    series.name = this.dataFields.value;
    series.columns.template.cursorOverStyle = MouseCursorStyle.pointer;
    if (this.customTooltip) {
      series.tooltip.label.html = `{categoryX} : <br/> {intValue}`;
    }

    series.columns.template.tooltipText = this.dataFields.tooltipTextCat
      ? `[bold black]${this.dataFields.tooltipTextCat}: {valueY}[/]`
      : "[bold black]{categoryX}: {valueY}[/]";
    series.columns.template.fillOpacity = 1;
    const columnTemplate = series.columns.template;
    columnTemplate.column.cornerRadiusTopLeft = 8;
    columnTemplate.column.cornerRadiusTopRight = 8;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 0.8;
    columnTemplate.stroke = color("#FFFFFF");
    if (this.columnBackground) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color("#111");
      series.tooltip.background.fillOpacity = 0.5;
    }
    return { series, columnTemplate };
  }

  colorColumns(chart: XYChart, series: ColumnSeries, columnTemplate: Column) {
    // create column template
    series.columns.template.adapter.add("fill", (fill, target) => {
      const pattern = new LinePattern();
      pattern.width = 10;
      pattern.height = 10;
      pattern.strokeWidth = 1;
      const strokeTarget: any =
        target && target.dataItem ? target.dataItem.dataContext : "";
      pattern.stroke = strokeTarget.color;
      pattern.rotation = 45;
      return pattern;
    });
    columnTemplate.background.adapter.add("fill", (fill, target) => {
      if (target.dataItem) {
        const object: any = target.dataItem.dataContext;
        return object.color;
      }
      return fill;
    });
  }

  // defaultColorColumns(chart: XYChart, columnTemplate: Column) {
  //   columnTemplate.adapter.add('fill', (fill, target) => {
  //     if (!this.categorize) {
  //       return chart.colors.getIndex(target.dataItem!.index);
  //     }
  //     const { dataItem } = target;
  //     if (dataItem.valueY >= Number(this.customCategory.split)) {
  //       return color(this.customCategory.firstColor).saturate(0.6);
  //     }
  //     return color(this.customCategory.secondColor);
  //   });

  //   // color Columns Stroke
  //   if (this.strokeColor) {
  //     columnTemplate.adapter.add('stroke', (stroke, target) => {
  //       if (!this.categorize) {
  //         return chart.colors.getIndex(target.dataItem!.index);
  //       }
  //       const { dataItem } = target;
  //       console.log("dataItemdataItemdataItemdataItem", dataItem);
  //       if (dataItem.valueY >= 50) {
  //         return color('#13ad53');
  //       }
  //       return color('#a8b3b7');
  //     });
  //   }
  // }

  labelBullet(series: ColumnSeries) {
    // label bullet
    const labelBullet = new LabelBullet();
    series.bullets.push(labelBullet);
    labelBullet.label.text = this.labelBulletObject.text
      ? `[bold]${this.labelBulletObject.text}: {valueY}[/]`
      : '{valueY.value.formatNumber("#.")}';
    labelBullet.strokeOpacity = 0;
    const strokeColor = this.labelBulletObject.strokeColor
      ? color(this.labelBulletObject.strokeColor)
      : color("#dadada");
    labelBullet.stroke = strokeColor;
    labelBullet.dy = this.labelBulletObject.dy ? this.labelBulletObject.dy : 0;
    labelBullet.dx = this.labelBulletObject.dx ? this.labelBulletObject.dx : 0;
  }

  enableScrolling(chart: XYChart) {
    chart.scrollbarX = new Scrollbar();
    const cursor = new XYCursor();
    cursor.behavior = this.scrollType ? this.scrollType : "zoomX";
    chart.cursor = cursor;
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
  }

  setMenuItems(chart: XYChart) {
    chart.exporting.menu = new ExportMenu();
    chart.exporting.menu.align = this.menuItemPostion;
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          { type: "png", label: "PNG" },
          { type: "jpg", label: "JPG" },
          {
            type: "print",
            label: `${this.$t("print")}`,
          },
        ],
      },
    ];
  }

  mounted() {
    this.drawChart();
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
