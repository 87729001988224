/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DashboardSubjectsResponse,
  DashboardCoursesResponse,
  DashboardQuestionsResponse,
  DashboardExamsResponse,
  SyncCoursesResponse,
  RefreshSessionResponse,
} from '@/types/dashboard.type';
import provider from './main.provider';

export default class DashboardProvider {
  async getDashboardCourses(activeStageId): Promise<DashboardCoursesResponse> {
    return provider.get(`/api/dashboard/getDashboardCourses?activeStageId=${activeStageId}`);
  }

  async getDashboardSubjects(activeStageId): Promise<DashboardSubjectsResponse> {
    return provider.get(`/api/dashboard/getDashboardSubjects?activeStageId=${activeStageId}`);
  }

  async getDashboardQuestions(activeStageId): Promise<DashboardQuestionsResponse> {
    return provider.get(`/api/dashboard/getDashboardQuestions?activeStageId=${activeStageId}`);
  }

  async getDashboardExams(activeStageId): Promise<DashboardExamsResponse> {
    return provider.get(`/api/dashboard/getDashboardExams?activeStageId=${activeStageId}`);
  }

  async syncCourses(type): Promise<SyncCoursesResponse> {
    return provider.get(`/api/dashboard/syncCourses/${type}`);
  }

  async refreshSession(): Promise<RefreshSessionResponse> {
    return provider.get('/api/dashboard/refreshSession');
  }
}
