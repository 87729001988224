

















import { Component, Vue, Prop } from 'vue-property-decorator';
import BarChartComponent from '../../shared/barChart.component.vue';
import { LegendObject } from '../../types/barChart.type';
import { DifficultyExamType, ExamInfo, Spec } from '../../types/exam.type';

@Component({
  components: { BarChartComponent },
})
export default class ExamchartComponent extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  readonly specs!: Spec[];

  @Prop({
    type: Object,
    default: () => ({
      totalDegree: 0,
      examTypeName: '',
      semesterName: '',
      minDifficulty: 0,
      maxDifficulty: 0,
      questionTypesCounts: [],
      instructorId: '',
      modelsMatch: '',
      examSignature: '',
      examDate: '',
      Degree: false,
      questions: [],
    }),
  })
  readonly info!: ExamInfo;

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly difficultyLevelRanges!: DifficultyExamType[];

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly selectedQuestionsIds!: number[];

  labelMaxWidth = 90;

  chartMax = 2000;

  labelBreak = 1500;

  dataFields = {
    category: 'status',
    value: 'value',
    tooltipTextCat: 'hend',
  };

  legendObject: LegendObject = {
    marginTop: 30,
    markerRounded: false,
    clickable: false,
    data: [
      {
        name: 'underRequiredRatio',
        fill: 'gray',
      },
      {
        name: 'onRequiredRatio',
        fill: 'green',
      },
      {
        name: 'overRequiredRatio',
        fill: 'red',
      },
    ],
  };

  labelBulletObject = {
    dy: -10,
  };

  get chartTitle() {
    return this.$t('model_percentages_chart_title');
  }

  get examInfo() {
    return { ...this.info };
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get examData() {
    return [
      {
        color: this.columnColors(this.totalDegreeChart),
        status: this.$t('labels.totalDegree'),
        value: this.totalDegreeChart,
        intValue: this.QuestionCountInitVal(
          this.chartData.totalDegree,
          this.chartData.examDefinedTotalDegree,
        ),
      },
      {
        color: this.columnColors(this.totalTimeChart),
        intValue: this.QuestionCountInitVal(
          this.chartData.totalTime,
          this.chartData.examDefinedTotalTime,
        ),
        status: this.$t('labels.totalTime'),
        value: this.totalTimeChart,
      },
      {
        status: this.$t('labels.hardQuestions'),
        color: this.columnColors(this.calcPercentage(this.targetHardCount, this.hardQuestionCount)),
        intValue: this.QuestionCountInitVal(
          this.hardQuestionCount,
          this.targetHardCount,
        ),
        value: this.calcPercentage(this.targetHardCount, this.hardQuestionCount),
      },
      {
        status: this.$t('labels.medQuestions'),
        color: this.columnColors(this.calcPercentage(this.targetMedCount, this.mediumQuestionCount)),
        value: this.calcPercentage(this.targetMedCount, this.mediumQuestionCount),
        intValue: this.QuestionCountInitVal(
          this.mediumQuestionCount,
          this.targetMedCount,
        ),
      },
      {
        status: this.$t('labels.easyQuestions'),
        color: this.columnColors(this.calcPercentage(this.targetEasyCount, this.easyQuestionCount)),
        value: this.calcPercentage(this.targetEasyCount, this.easyQuestionCount),
        intValue: this.QuestionCountInitVal(
          this.easyQuestionCount,
          this.targetEasyCount,
        ),
      },
      {
        status: this.$t('labels.COUNT_OF_QUESTIONS'),
        color: this.columnColors(this.QuestionCountChart),
        intValue: this.QuestionCountInitVal(
          this.chartData.currentTotalQuestionCount,
          this.chartData.examDefinedQuestionCount,
        ),
        value: this.QuestionCountChart,
      },
    ];
  }

  QuestionCountInitVal(x, y) {
    if (this.lang === 'ar') {
      return `الحالى : ${x} 
    <br /> المتوقع: ${y}`;
    }
    return `Actual : ${x} 
    <br /> Target: ${y}`;
  }

  get QuestionCountChart() {
    return Math.round(
      (this.chartData.currentTotalQuestionCount
        / this.chartData.examDefinedQuestionCount)
        * 100,
    );
  }

  get easyQuestionsChart() {
    const val = Math.round(
      this.fixNaNAndInfinityValues(this.easyRelativePercentage),
    );
    return val;
  }

  get medQuestionsChart() {
    const val = Math.round(
      this.fixNaNAndInfinityValues(this.mediumRelativePercentage),
    );
    return val;
  }

  get targetHardCount() {
    return Math.round(
      this.chartData.examDefinedQuestionCount
        * (this.chartData.examDefinedHardPercentage / 100),
    );
  }

  get targetEasyCount() {
    return Math.round(
      this.chartData.examDefinedQuestionCount
        * (this.chartData.examDefinedEasyPercentage / 100),
    );
  }

  get targetMedCount() {
    return Math.round(
      this.chartData.examDefinedQuestionCount
        * (this.chartData.examDefinedMediumPercentage / 100),
    );
  }

  get hardQuestionsChart() {
    const val = Math.round(
      this.fixNaNAndInfinityValues(this.hardRelativePercentage),
    );

    return val;
  }

  get totalTimeChart() {
    return Math.round(
      (this.chartData.totalTime / this.chartData.examDefinedTotalTime) * 100,
    );
  }

  get totalDegreeChart() {
    return (
      (this.chartData.totalDegree / this.chartData.examDefinedTotalDegree) * 100
    );
  }

  columnColors(value: number) {
    if (value > 100) {
      return 'red';
    }
    if (value === 100) {
      return 'green';
    }
    return 'gray';
  }

  get chartData() {
    return {
      examDefinedQuestionCount: this.examDefinedQuestionsCount,
      currentTotalQuestionCount: this.totalQuesNo,
      easyQuestionDegrees: this.easyQuestionDegrees,
      mediumQuestionDegrees: this.mediumQuestionDegrees,
      hardQuestionDegrees: this.hardQuestionDegrees,
      totalTime: this.totalTime,
      totalDegree: this.totalDegree,
      examDefinedTotalTime:
        this.examInfo && this.examInfo.totalTime ? this.examInfo.totalTime : 0,
      examDefinedTotalDegree:
        this.examInfo && this.examInfo.totalDegree
          ? this.examInfo.totalDegree
          : 0,
      examDefinedEasyPercentage:
        this.examInfo && this.examInfo.easyPercentage
          ? this.examInfo.easyPercentage
          : 0,
      examDefinedMediumPercentage:
        this.examInfo && this.examInfo.mediumPercentage
          ? this.examInfo.mediumPercentage
          : 0,
      examDefinedHardPercentage:
        this.examInfo && this.examInfo.hardPercentage
          ? this.examInfo.hardPercentage
          : 0,
    };
  }

  get easyQuestionDegrees() {
    return this.getDistData().easyQuestionDegrees;
  }

  get easyQuestionPercentage() {
    return (
      (this.chartData.easyQuestionDegrees
        / this.chartData.examDefinedTotalDegree)
      * 100
    );
  }

  get easyRelativePercentage() {
    return (
      (this.easyQuestionPercentage / this.chartData.examDefinedEasyPercentage)
      * 100
    );
  }

  get mediumQuestionDegrees() {
    return this.getDistData().mediumQuestionDegrees;
  }

  get mediumQuestionPercentage() {
    return (this.mediumQuestionDegrees / this.examInfo.totalDegree) * 100;
  }

  get mediumRelativePercentage() {
    return (
      (this.mediumQuestionPercentage
        / this.chartData.examDefinedMediumPercentage)
      * 100
    );
  }

  get hardQuestionDegrees() {
    return this.getDistData().hardQuestionDegrees;
  }

  get hardRelativePercentage() {
    return (
      (this.hardQuestionPercentage / this.chartData.examDefinedHardPercentage)
      * 100
    );
  }

  get hardQuestionPercentage() {
    return (
      (this.chartData.hardQuestionDegrees
        / this.chartData.examDefinedTotalDegree)
      * 100
    );
  }

  get totalQuesNo() {
    return this.getDistData().totalQuesNo;
  }

  get totalDegree() {
    return this.getDistData().totalDegree;
  }

  get examDefinedQuestionsCount() {
    if (this.examInfo && this.examInfo.questionTypesCounts.length) {
      return this.examInfo.questionTypesCounts.reduce(
        (acc, item) => acc + item.questionCount,
        0,
      );
    }
    if (this.examInfo && this.examInfo.questions) {
      return this.examInfo.questions.reduce(
        (acc, item) => acc + Number(item.countOfQuestions),
        0,
      );
    }
    return 0;
  }

  getDistData() {
    return this.specs.reduce(
      (Acc, spec) => {
        const modelAcc = Acc;

        const checkedQues = spec.questions.filter((ques) => this.selectedQuestionsIds.includes(ques.questionId));
        const { specTotalTime, specTotalDifficulty } = this.getSpecDistDataObj(checkedQues);
        modelAcc.totalQuesNo += checkedQues.length;
        modelAcc.totalTime += specTotalTime;
        modelAcc.totalDifficulty += specTotalDifficulty;

        modelAcc.totalDegree = Number(
          (
            Number(modelAcc.totalDegree)
            + checkedQues.reduce(
              (acc, item) => Number(acc) + Number(item.degree),
              0,
            )
          ).toFixed(2),
        );

        modelAcc.easyQuestionDegrees = Number(
          (
            modelAcc.easyQuestionDegrees
            + checkedQues.reduce(
              (acc, item) => acc + (this.isQuestionEasy(item) ? Number(item.degree) : 0),
              0,
            )
          ).toFixed(2),
        );

        modelAcc.easyQuestionCount += checkedQues.filter((item) => this.isQuestionEasy(item)).length;
        modelAcc.hardQuestionCount += checkedQues.filter((item) => this.isQuestionHard(item)).length;
        modelAcc.mediumQuestionCount += checkedQues.filter((item) => this.isQuestionMedium(item)).length;

        modelAcc.mediumQuestionDegrees = Number(
          (
            modelAcc.mediumQuestionDegrees
            + checkedQues.reduce(
              (acc, item) => acc + (this.isQuestionMedium(item) ? Number(item.degree) : 0),
              0,
            )
          ).toFixed(2),
        );
        modelAcc.hardQuestionDegrees = Number(
          (
            modelAcc.hardQuestionDegrees
            + checkedQues.reduce(
              (acc, item) => acc + (this.isQuestionHard(item) ? Number(item.degree) : 0),
              0,
            )
          ).toFixed(2),
        );

        return modelAcc;
      },
      {
        totalTime: 0,
        totalDifficulty: 0,
        totalQuesNo: 0,
        totalDegree: 0,
        easyQuestionDegrees: 0,
        mediumQuestionDegrees: 0,
        hardQuestionDegrees: 0,
        easyQuestionCount: 0,
        hardQuestionCount: 0,
        mediumQuestionCount: 0,
      },
    );
  }

  isQuestionEasy(question) {
    const difficultyLevel = this.difficultyLevelRanges.find(
      (range) => question.difficulty >= range.min && question.difficulty < range.max,
    );
    return difficultyLevel && difficultyLevel.id === '6.1.';
  }

  isQuestionMedium(question) {
    const difficultyLevel = this.difficultyLevelRanges.find(
      (range) => question.difficulty >= range.min && question.difficulty < range.max,
    );
    return difficultyLevel && difficultyLevel.id === '6.2.';
  }

  isQuestionHard(question) {
    const difficultyLevel = this.difficultyLevelRanges.find(
      (range) => question.difficulty >= range.min && question.difficulty < range.max,
    );
    return difficultyLevel && difficultyLevel.id === '6.3.';
  }

  get totalTime() {
    return this.getDistData().totalTime;
  }

  get easyQuestionCount() {
    return this.getDistData().easyQuestionCount;
  }

  get hardQuestionCount() {
    return this.getDistData().hardQuestionCount;
  }

  get mediumQuestionCount() {
    return this.getDistData().mediumQuestionCount;
  }

  calcPercentage(max, min) {
    return this.fixNaNAndInfinityValues((min / max) * 100);
  }

  fixNaNAndInfinityValues(value) {
    if (Number.isNaN(value)) {
      return 100;
    }
    if (value === Infinity) {
      return this.chartMax;
    }
    return value;
  }

  getSpecDistDataObj(questions) {
    return questions.reduce(
      (acc, ques) => {
        const specAcc = acc;
        const stringTime = ques.time ? ques.time : ques.default_time;
        specAcc.specTotalTime += this.calcTimeInMin(stringTime);
        specAcc.specTotalDifficulty += ques.difficulty;
        return specAcc;
      },
      { specTotalTime: 0, specTotalDifficulty: 0 },
    );
  }

  calcTimeInMin(time) {
    const timeArray = time.split(':');
    return (
      Number(timeArray[0]) * 60
      + Number(timeArray[1])
      + Number(timeArray[2]) / 60
    );
  }
}
