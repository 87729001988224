




































































import { Component, Prop } from 'vue-property-decorator';
import { SpecQuestion } from '../../types/exam.type';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import { QUESION_TYPES } from '../../enums';
import { QuestionTypeKeyFunction } from '../../types/questions.type';

@Component
export default class ViewCaseStudySubQuestions extends MathJaxMixin {
  @Prop() readonly caseStudyQuestion!: SpecQuestion;

  @Prop() readonly appDrawerOpen!: boolean;

  @Prop() readonly questionIndex!: number;

  @Prop() readonly specIndex!: number;

  @Prop() readonly examDirection!: string;

  @Prop({ default: 2 }) readonly fractionNumber!: number;

  @Prop() readonly viewOnly!: boolean;

  @Prop({ default: true, type: Boolean }) readonly questionChecked!: boolean;

  questionTypesKeys = QUESION_TYPES;

  rules = {
    questionDegree: [
      (val: string) => !!val || this.$t('validations.required'),
      (val: number) => !(val < 0.01) || this.$t('validations.minValue', { min: 0.01 }),
      (val: number) => val <= 999 || this.$t('validations.maxValue', { max: 999 }),
      (val: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(val) || this.$t('validations.maxFloating'),
    ],
  };

  get lang() {
    return this.$store.state.lang.lang;
  }

  getTypeName(subquestion) {
    if (subquestion && subquestion.typeId) {
      return (this.questionTypesKeys[subquestion.typeId] as QuestionTypeKeyFunction)()?.defaultText;
    } if (subquestion && subquestion.type_id) {
      return (this.questionTypesKeys[subquestion.type_id] as QuestionTypeKeyFunction)()?.defaultText;
    }
    return '';
  }

  preventEventBubbling(event) {
    if (!this.viewOnly) {
      event.stopPropagation();
    }
  }

  reCalculateTotalDegree() {
    if (
      this.caseStudyQuestion
      && this.caseStudyQuestion.caseStudySubQuestions
      && this.caseStudyQuestion.caseStudySubQuestions.length
    ) {
      const totalDegree = this.caseStudyQuestion.caseStudySubQuestions.reduce(
        (sum, current) => Number(sum.toFixed(this.fractionNumber))
          + Number(Number(current.degree).toFixed(this.fractionNumber)),
        0,
      );

      this.$emit('degreeChanged', {
        totalDegree,
        SpecIndex: this.specIndex,
        questionIndex: this.questionIndex,
      });
    }
  }
}
