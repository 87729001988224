

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Answer, MatchingSubQuestion } from '../../types/questions.type';

@Component
export default class MatchingViewPaper extends Vue {
  @Prop(Array) readonly answers!: Answer[];

  @Prop(Array) readonly matchingSubQuestions!: MatchingSubQuestion[];
}
