var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.matrixData || !_vm.matrixData.length)?_c('div',[_c('v-alert',{staticClass:"text-center ma-2 white--text",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('messages.noMatrixYet'))+" ")])],1):_c('div',[_c('v-simple-table',{staticClass:"disable-table-hover-effect bordered-table--light",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.mainHeaders),function(mainHeader,mainHeaderIndex){return _c('th',{key:mainHeaderIndex,class:mainHeader.class,attrs:{"colspan":mainHeader.colspan || 1,"rowspan":mainHeader.rowspan || 1}},[_vm._v(" "+_vm._s(mainHeader.text)+" ")])}),0),_c('tr',_vm._l((_vm.ilosHeaders),function(iloHeader,iloHeaderIndex){return _c('th',{key:iloHeaderIndex,class:iloHeader.class,attrs:{"colspan":iloHeader.colspan || 1,"rowspan":iloHeader.rowspan || 1}},[_c('h4',[_vm._v(" "+_vm._s(iloHeader.text)+" ")]),_c('span',[_vm._v("( "+_vm._s(iloHeader.count)+" )")])])}),0)]),_c('tbody',[_vm._l((_vm.filteredSubjects),function(subjectData,subjectDataIndex){return [_c('tr',{key:subjectDataIndex},[_c('td',{class:_vm.columnClass,attrs:{"rowspan":"2"}},[_vm._v(_vm._s(subjectDataIndex + 1))]),_c('td',{class:_vm.columnClass,attrs:{"rowspan":"2"}},[_c('h4',[_vm._v(" "+_vm._s(subjectData.subjectName || '-')+" ")])]),_c('td',{class:_vm.columnClass},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('labels.questions'))+" ")])]),_vm._l((_vm.ilosData),function(iloData,iloDataIndex){return _c('td',{key:iloDataIndex,class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                            color: _vm.getColor(
                                                subjectData.subjectId,
                                                iloData.iloId,
                                                _vm.questionsKey
                                            )
                                        })},[_vm._v(" "+_vm._s(_vm.getCellActualValue( subjectData.subjectId, iloData.iloId, _vm.questionsKey ))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedCellValue( subjectData.subjectId, iloData.iloId, _vm.questionsKey ))+" ")])],2)])}),_c('td',{class:_vm.columnClass,attrs:{"rowspan":"2"}},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                            color: _vm.getColor(
                                                subjectData.subjectId, null, _vm.questionsKey
                                            )
                                        })},[_vm._v(" "+_vm._s(_vm.getActualTotalSubjectsValue( subjectData.subjectId, _vm.questionsKey ))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v("/")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedTotalSubjectsValue( subjectData.subjectId, _vm.questionsKey ))+" ")])],2),(_vm.isModelBlueprint)?_c('div',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTotalSubjectsPercent( subjectData.subjectId, _vm.questionsKey ))+" % ")]),_c('span',[_vm._v(" ( "+_vm._s(_vm.getActualTotalSubjectsValue( subjectData.subjectId, _vm.questionsKey ))+" ) ")])]):_vm._e()]),_c('td',{class:_vm.columnClass,attrs:{"rowspan":"2"}},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                            color: _vm.getColor(
                                                subjectData.subjectId, null, _vm.degreesKey
                                            )
                                        })},[_vm._v(" "+_vm._s(_vm.getActualTotalSubjectsValue( subjectData.subjectId, _vm.degreesKey ))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedTotalSubjectsValue( subjectData.subjectId, _vm.degreesKey ))+" ")])],2),(_vm.isModelBlueprint)?_c('div',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTotalSubjectsPercent( subjectData.subjectId, _vm.degreesKey ))+" % ")]),_c('span',[_vm._v(" ( "+_vm._s(_vm.getActualTotalSubjectsValue( subjectData.subjectId, _vm.degreesKey ))+" ) ")])]):_vm._e()]),_c('td',{class:_vm.columnClass,attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.getSubjectWeight(subjectData.subjectId))+" % ")])],2),_c('tr',{key:("D" + subjectDataIndex)},[_c('td',{class:_vm.columnClass},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('labels.degrees'))+" ")])]),_vm._l((_vm.ilosData),function(iloData,iloDataIndex){return _c('td',{key:iloDataIndex,class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                            color: _vm.getColor(
                                                subjectData.subjectId, iloData.iloId, _vm.degreesKey
                                            )
                                        })},[_vm._v(" "+_vm._s(_vm.getCellActualValue( subjectData.subjectId, iloData.iloId, _vm.degreesKey ))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedCellValue( subjectData.subjectId, iloData.iloId, _vm.degreesKey ))+" ")])],2)])})],2)]}),_c('tr',[_c('td',{class:_vm.columnClass,attrs:{"colspan":"3"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('labels.totalQuestions'))+" ")])]),_vm._l((_vm.ilosData),function(iloData,iloDataIndex){return _c('td',{key:iloDataIndex,class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                        color: _vm.getColor(null, iloData.iloId, _vm.questionsKey)
                                    })},[_vm._v(" "+_vm._s(_vm.getActualTotalIloValue(iloData.iloId, _vm.questionsKey))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedTotalIloValue(iloData.iloId, _vm.questionsKey))+" ")])],2),(_vm.isModelBlueprint)?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTotalIlosPercent(iloData.iloId, _vm.questionsKey))+" % ")]),_c('span',[_vm._v(" ( "+_vm._s(_vm.getActualTotalIloValue(iloData.iloId, _vm.questionsKey))+" ) ")])]):_vm._e()])}),_c('td',{class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                        'color': (_vm.totalQuestions === _vm.expectedTotalQuestions)?
                                            _vm.matchingColor: _vm.unMatchingColor
                                    })},[_vm._v(" "+_vm._s(_vm.totalQuestions)+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(_vm._s(_vm.expectedTotalQuestions))])],2)]),_c('td'),_c('td')],2),_c('tr',[_c('td',{class:_vm.columnClass,attrs:{"colspan":"3"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('labels.totalDegrees'))+" ")])]),_vm._l((_vm.ilosData),function(iloData,iloDataIndex){return _c('td',{key:iloDataIndex,class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                        color: _vm.getColor(null,iloData.iloId, _vm.degreesKey)
                                    })},[_vm._v(" "+_vm._s(_vm.getActualTotalIloValue(iloData.iloId, _vm.degreesKey))+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getExpectedTotalIloValue(iloData.iloId, _vm.degreesKey))+" ")])],2),(_vm.isModelBlueprint)?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTotalIlosPercent(iloData.iloId, _vm.degreesKey))+" % ")]),_c('span',[_vm._v(" ( "+_vm._s(_vm.getActualTotalIloValue(iloData.iloId, _vm.degreesKey))+" ) ")])]):_vm._e()])}),_c('td'),_c('td',{class:_vm.columnClass},[_c('div',{staticClass:"text-center"},[(_vm.isModelBlueprint)?[_c('span',{style:({
                                        'color': (_vm.totalDegrees === _vm.expectedTotalDegrees)?
                                            _vm.matchingColor: _vm.unMatchingColor
                                    })},[_vm._v(" "+_vm._s(_vm.totalDegrees)+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" / ")])]:_vm._e(),_c('span',[_vm._v(_vm._s(_vm.expectedTotalDegrees))])],2)]),_c('td')],2),_c('tr',[_c('td',{class:_vm.columnClass,attrs:{"colspan":"3"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('tableHeaders.iloWeight'))+" ")])]),_vm._l((_vm.ilosData),function(iloData,iloDataIndex){return _c('td',{key:iloDataIndex,class:_vm.columnClass},[_c('span',[_vm._v(" "+_vm._s(_vm.getIloWeight(iloData.iloId))+" % ")])])}),_c('td'),_c('td'),_c('td',{class:_vm.columnClass},[_c('span',[_vm._v(" 100 % ")])])],2)],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }