







































import { Component, Vue, Prop } from 'vue-property-decorator';
import DashboardProvider from '../providers/dashboard.provider';
import TimerComponent from './timer.component.vue';

@Component({
  components: {
    TimerComponent,
  },
})
export default class SessionRefreshComponent extends Vue {
  @Prop({ default: '600px' }) readonly width!: boolean;

  @Prop({ default: false }) readonly fullscreen!: boolean;

  @Prop({ default: ' dialog-transition' }) readonly transition!: boolean;

  @Prop({ default: 120 }) readonly allowedTimerSeconds!: number;

  $refs!: {
    timer: TimerComponent;
  };

  dashboardProvider = new DashboardProvider();

  dialogTimer = '00:01:00';

  showDialog = false;

  showConfirmButton = true;

  CheckInterval;

  get sessionExpiryDate() {
    const expiry = this.$store.state.auth.sessionExpiry;
    if (expiry) {
      expiry.setSeconds(expiry.getSeconds() - this.allowedTimerSeconds);
    }
    return expiry;
  }

  mounted() {
    this.startCheckInterval();
  }

  destroyed() {
    this.clearCheckInterval();
  }

  async refresh() {
    this.closeDialog();
    await this.dashboardProvider.refreshSession();
    this.startCheckInterval();
  }

  logout() {
    this.showConfirmButton = false;
    this.$store.commit('logout');
    this.$router.push(`/login?redirect=${window.location.pathname}`);
  }

  startCheckInterval() {
    this.clearCheckInterval();
    this.CheckInterval = setInterval(() => {
      const currentDate = new Date(Date.now());
      if (this.sessionExpiryDate <= currentDate) {
        const timeDiff = Math.round(
          this.allowedTimerSeconds - (currentDate.getTime() - this.sessionExpiryDate.getTime()) / 1000,
        );
        const minutes = Math.max(Math.floor(timeDiff / 60), 0);
        const remainingSeconds = Math.max(timeDiff - minutes * 60, 0);
        this.showConfirmButton = true;
        this.dialogTimer = `00:${minutes}:${remainingSeconds}`;
        this.openDialog();
        this.clearCheckInterval();
      }
    }, 1000);
  }

  clearCheckInterval() {
    if (this.CheckInterval) {
      clearInterval(this.CheckInterval);
    }
  }

  openDialog() {
    if (this.$refs.timer) {
      this.$refs.timer.resetTime();
      this.$refs.timer.formateTime();
      this.$refs.timer.startTimer();
    }
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
    this.$emit('close');
  }
}
