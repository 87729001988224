/* eslint-disable @typescript-eslint/no-explicit-any */import {
  SpecQuestion, FetchExamDataRes, SimilarityData,
} from '@/types/exam.type';
import { Lang } from '@/types/general.type';
import provider from './main.provider';

class ExamProvider {
  // async getModelData(examId: string): Promise<FetchExamDataRes> {
  //   const result: FetchExamDataRes = await provider.get(`/api/courses/${examId}/getList`, {
  //     params: '',
  //   });
  //   return result;
  // }

  async fetchExamData(appId: string, courseId: string, examId: string, query: object):
    Promise<FetchExamDataRes> {
    const result: FetchExamDataRes = await provider.get(`/api/generatedExam/${appId}/getExamDataModel/${courseId}/${examId}`, {
      params: query,
    });
    return result;
  }

  async getQuestions(examId: string, query: object): Promise<FetchExamDataRes> {
    const result: FetchExamDataRes = await provider.get(`/generatedExam/getQuestions/${examId}`, {
      params: query,
    });
    return result;
  }

  async getSimilarQuestion(
    appId: string, courseId: string, questionId: number, reqBody: object,
  ): Promise<SpecQuestion> {
    const { similarQuestions }: { similarQuestions: SpecQuestion[] } = await provider.post(
      `/api/generatedExam/${appId}/${courseId}/getSimilarQuestion/${questionId}`,
      reqBody,
    );
    return similarQuestions[0];
  }

  async getModelSimilarity(
    appId: string, courseId: string, examId: string, query: object, reqBody: object,
  ): Promise<SimilarityData> {
    const { similarityData }: { similarityData: SimilarityData } = await provider.post(
      `/api/generatedExam/${appId}/${courseId}/${examId}/getModelSimilarity`,
      reqBody,
      { params: query },
    );
    return similarityData;
  }

  async addExamModel(
    appId: string, courseId: string, examId: string, reqBody: object,
  ): Promise<{ modelNumber: number; message: Lang }> {
    const res: { modelNumber: number; message: Lang } = await provider.put(
      `/api/generatedExam/${appId}/${courseId}/${examId}/addModel`,
      reqBody,
    );
    return res;
  }

  async editExamModel(
    appId: string, courseId: string, examId: string, modelNo: string, reqBody: object,
  ): Promise<{ message: Lang }> {
    const res: { message: Lang } = await provider.put(
      `/api/generatedExam/${appId}/${courseId}/${examId}/editModel/${modelNo}`,
      reqBody,
    );
    return res;
  }

  async getSpecQuestions(
    appId: string, courseId: string, reqBody,
  ): Promise<any> {
    const res = await provider.post(`/api/generatedExam/${appId}/getSpecQuestions/${courseId}`, { ...reqBody });
    return res;
  }

  async copyExamModel(
    appId: string, courseId: string, examId: string, modelNo: string, reqBody: object,
  ): Promise<{ modelNumber: number; message: Lang }> {
    const res: { modelNumber: number; message: Lang } = await provider.put(
      `/api/generatedExam/${appId}/${courseId}/${examId}/copyModel/${modelNo}`,
      reqBody,
    );
    return res;
  }
}
export default new ExamProvider();
