




















































































































































import { Component, Prop } from 'vue-property-decorator';
import ViewMatchingColumns from '../questions/matching/viewMatchingColumns.vue';

import { SpecQuestion } from '../../types/exam.type';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import { QUESION_TYPES } from '../../enums';
import Utils from '../../shared/utils';

@Component({
  components: {
    ViewMatchingColumns,
  },
})
export default class ViewQuestionDetails extends MathJaxMixin {
  @Prop() readonly question!: SpecQuestion;

  @Prop({ default: false }) readonly isSubQuestion!: boolean;

  @Prop({ default: false }) readonly difficultyNeedFix!: boolean;

  @Prop({ default: false }) readonly timeNeedFix!: boolean;

  questionTypesKeys = QUESION_TYPES;

  getStringTime(averageTime: string | number): string {
    return Utils.getStringTime(averageTime);
  }

  isCaseStudyQuestion(question: SpecQuestion): boolean {
    return question.typeId === QUESION_TYPES.CASE_STUDY.id;
  }
}
