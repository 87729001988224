

























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { DegreesDistribution } from '../../types/exam.type';

@Component
export default class DegreesDistributionForm extends Vue {
  distribution: DegreesDistribution = {
    time: false,
    difficulty: false,
    equality: false,

  };

  applyDistribution() {
    if (!this.distribution.equality) this.$emit('distributeDegrees', this.distribution);
    else {
      this.$emit('distributeDegreesEqually');
    }
  }

  changeTimeDifficulityStatus() {
    this.distribution.time = false;
    this.distribution.difficulty = false;
  }
}
