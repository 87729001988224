




































































































































































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  ExamIlos,
  ModelSpec,
  SpecQuestion,
  ExamSubjects,
  ExamMatrixRecord,
  FormalizedMatrix,
} from '../types/exam.type';

@Component
export default class MatrixBlueprintComponent extends Vue {
    @Prop({ default: false }) readonly isModelBlueprint!: boolean;

    @Prop({
      type: Array,
      default: () => [],
    }) readonly modelSpecs!: ModelSpec[];

    @Prop({
      type: Array,
      default: () => [],
    }) ilosData!: ExamIlos[];

    @Prop({
      type: Array,
      default: () => [],
    }) subjectsData!: ExamSubjects[];

    @Prop({
      type: Array,
      default: () => [],
    }) readonly matrixData!: ExamMatrixRecord[];

    servertranslate = this.$options.filters?.servertranslate || (() => '');

    headerClass = 'white--text pa-3 qb-table__header text-center';

    columnClass = 'pa-3 text-center';

    questionsKey = 'questions';

    degreesKey = 'degrees';

    matchingColor = '#1db231';

    unMatchingColor = '#ffc107';

    get mainHeaders() {
      return [
        {
          text: this.$t('tableHeaders.index'),
          rowspan: 2,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.subject'),
          rowspan: 2,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.questionsAndDegrees'),
          rowspan: 2,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.ILOs'),
          colspan: this.ilosData.length,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.questionsCount'),
          rowspan: 2,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.degreesSum'),
          rowspan: 2,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.subjectWeight'),
          rowspan: 2,
          class: this.headerClass,
        },
      ];
    }

    get ilosHeaders() {
      return this.ilosData.map((iloData) => ({
        text: this.servertranslate(iloData.iloName),
        count: iloData.ilosCount,
        class: this.headerClass,
      }));
    }

    get questions(): SpecQuestion[] {
      return this.modelSpecs
        ? this.modelSpecs.reduce(
          (acc, spec) => [...acc, ...spec.questions], [] as SpecQuestion[],
        ) : [];
    }

    get totalDegrees() {
      return Number(this.questions.reduce((acc, question) => {
        /* eslint-disable no-param-reassign */
        acc += Number(question.degree);
        /* eslint-enable no-param-reassign */
        return acc;
      }, 0).toFixed(2));
    }

    get totalQuestions() {
      return this.questions.length;
    }

    get expectedTotalQuestions() {
      return this.formalizedExamMatrix
        ? Object.values(this.formalizedExamMatrix).reduce((matrixRowsQuestionsAcc, matrixRow) => {
          /* eslint-disable no-param-reassign */
          matrixRowsQuestionsAcc += Object.values(matrixRow).reduce(
            (ilosQuestionsAcc, iloData) => {
              ilosQuestionsAcc += Number(iloData.questions);
              return ilosQuestionsAcc;
            }, 0,
          );
          /* eslint-enable no-param-reassign */
          return matrixRowsQuestionsAcc;
        }, 0) : 0;
    }

    get expectedTotalDegrees() {
      return this.formalizedExamMatrix
        ? Object.values(this.formalizedExamMatrix).reduce((matrixRowsDegreesAcc, matrixRow) => {
          /* eslint-disable no-param-reassign */
          matrixRowsDegreesAcc += Object.values(matrixRow).reduce((ilosDegreesAcc, iloData) => {
            ilosDegreesAcc += Number(iloData.degrees);
            return ilosDegreesAcc;
          }, 0);
          /* eslint-enable no-param-reassign */
          return matrixRowsDegreesAcc;
        }, 0) : 0;
    }

    get subjectsWeightsObj() {
      if (!this.matrixData) { return {}; }
      /* eslint-disable no-param-reassign */
      const totalLecturesCount = this.matrixData.reduce((lecturesCountAcc, matrixRow) => {
        const validSubject = this.filteredSubjects.find(
          (subject) => subject.subjectId === matrixRow.subject,
        );
        if (
          !matrixRow.lectureCount || !validSubject
          || Number.isNaN(Number(matrixRow.lectureCount))
        ) {
          return lecturesCountAcc;
        }
        return (lecturesCountAcc + Number(matrixRow.lectureCount));
      }, 0);

      return this.matrixData.reduce(
        (subjectsWeightsObjAcc, matrixRow) => {
          const subjectWeight = Number(matrixRow.lectureCount) / totalLecturesCount;
          subjectsWeightsObjAcc[matrixRow.subject] = Number.isNaN(subjectWeight)
            ? 0
            : Number((subjectWeight * 100).toFixed(2));
          /* eslint-enable no-param-reassign */
          return subjectsWeightsObjAcc;
        }, {},
      );
    }

    get filteredSubjects() {
      return this.subjectsData.filter((subjectData) => (
        this.formalizedExamMatrix[subjectData.subjectId] /* && (
          this.getExpectedTotalSubjectsValue(subjectData.subjectId, this.questionsKey)
          || this.getActualTotalSubjectsValue(subjectData.subjectId, this.questionsKey)
        ) */
      ));
    }

    get formalizedExamMatrix(): FormalizedMatrix {
      return this.matrixData ? this.matrixData.reduce((matrixRowsAcc, matrixRow) => {
        /* eslint-disable no-param-reassign */
        if (!matrixRowsAcc[matrixRow.subject]) {
          matrixRowsAcc[matrixRow.subject] = {};
        }
        matrixRow.ilos.forEach((iloData) => {
          matrixRowsAcc[matrixRow.subject][iloData.id] = {
            questions: Number(iloData.questions),
            degrees: Number(iloData.degrees),
            totalWeight:
              Number(
                (Number((iloData.totalWeight || (1 / matrixRow.ilos.length))) * 100).toFixed(2),
              ),
          };
        });
        /* eslint-enable no-param-reassign */
        return matrixRowsAcc;
      }, {}) : [];
    }

    get calculatedMatrix(): FormalizedMatrix {
      if (!this.subjectsData) { return {}; }
      const matrixSkeleton = this.subjectsData.reduce((acc, subjectData) => {
        if (!acc[subjectData.subjectId]) {
          acc[subjectData.subjectId] = {};
          this.ilosData.forEach((ilo) => {
            acc[subjectData.subjectId][ilo.iloId] = {
              questions: 0,
              degrees: 0,
              totalWeight: 0,
            };
          });
        }

        return acc;
      }, {});

      this.questions.forEach((question) => {
        if (question.subjId) {
          matrixSkeleton[question.subjId.toString()][question.iloId].questions += 1;
          matrixSkeleton[question.subjId.toString()][question.iloId].degrees = Number((
            matrixSkeleton[question.subjId.toString()][question.iloId].degrees
            + Number(question.degree)
          ).toFixed(2));
        }
      });
      return matrixSkeleton;
    }

    getColor(subjectId, iloId, key) {
      let matching = false;
      if (subjectId && iloId) {
        matching = (
          this.getExpectedCellValue(
            subjectId, iloId, key,
          ) === this.getCellActualValue(subjectId, iloId, key)
        );
      } else if (subjectId) {
        matching = (
          this.getExpectedTotalSubjectsValue(
            subjectId, key,
          ) === this.getActualTotalSubjectsValue(subjectId, key)
        );
      } else {
        matching = (
          this.getExpectedTotalIloValue(
            iloId, key,
          ) === this.getActualTotalIloValue(iloId, key)
        );
      }
      return matching ? this.matchingColor : this.unMatchingColor;
    }

    getExpectedCellValue(subjectId, iloId, key) {
      return this.formalizedExamMatrix[subjectId][iloId][key];
    }

    getCellActualValue(subjectId, iloId, key) {
      return this.calculatedMatrix[subjectId][iloId][key];
    }

    getExpectedTotalSubjectsValue(subjectId, key) {
      return Object.values(this.formalizedExamMatrix[subjectId]).reduce(
        (acc, iloData) => Number((acc + iloData[key]).toFixed(2)), 0,
      );
    }

    getActualTotalSubjectsValue(subjectId, key) {
      return Object.values(this.calculatedMatrix[subjectId]).reduce(
        (acc, iloData) => Number((acc + iloData[key]).toFixed(2)), 0,
      );
    }

    getTotalSubjectsPercent(subjectId, key) {
      const count = this.getActualTotalSubjectsValue(subjectId, key) as number;
      const total = (key === this.questionsKey) ? this.totalQuestions : this.totalDegrees;
      const percent = Math.round((count / total) * 10000) / 100;
      return Number.isNaN(percent) ? 0 : percent;
    }

    getExpectedTotalIloValue(iloId, key) {
      return Object.values(this.formalizedExamMatrix).reduce(
        (acc, subjectData) => Number((acc + subjectData[iloId][key]).toFixed(2)), 0,
      );
    }

    getActualTotalIloValue(iloId, key) {
      return Object.values(this.calculatedMatrix).reduce(
        (acc, subjectData) => Number((acc + subjectData[iloId][key]).toFixed(2)), 0,
      );
    }

    getTotalIlosPercent(iloId, key) {
      const count = this.getActualTotalIloValue(iloId, key) as number;
      const total = (key === this.questionsKey) ? this.totalQuestions : this.totalDegrees;
      const percent = Math.round((count / total) * 10000) / 100;
      return Number.isNaN(percent) ? 0 : percent;
    }

    getIloWeight(iloId) {
      return Object.values(this.formalizedExamMatrix)[0][iloId].totalWeight;
    }

    getSubjectWeight(subjectId) {
      return this.subjectsWeightsObj[subjectId];
    }
}
