

























import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { QUESION_TYPES, SUPPORTED_LANGS, ANSWER_CHOICES } from '../../enums';
import { CaseStudySubQuestion } from '../../types/questions.type';

@Component
export default class CaseStudyViewModelPaper extends Vue {
    @Prop(Array) readonly caseStudySubQuestions!: CaseStudySubQuestion[];

    @Prop(String) readonly examLang!: string;

    questionTypes = QUESION_TYPES;

    get subQuestions(): CaseStudySubQuestion[] {
      return this.caseStudySubQuestions ? this.caseStudySubQuestions : [];
    }

    translate(field) {
      return this.examLang === SUPPORTED_LANGS.EN ? field.en : field.ar;
    }

    get answerChoices() {
      return ANSWER_CHOICES;
    }
}
